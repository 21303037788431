import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

export default function SideBar() {
    const { t } = useTranslation()
    return (
        <>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t("activity")}</h4>
            </div>
            <div className="md:flex gap-5 block mb-4">
                <div className="md:w-16rem w-full p-card p-2">
                    <ul className="gap-2  flex justify-center md:block">
                        <li>
                            <NavLink
                                to="leads"
                                className={({ isActive }) =>
                                    `${isActive
                                        ? "text-primary bg-gray-200 "
                                        : "text-gray-900"
                                    }    group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`
                                }
                            >
                                <i className="pi pi-users"></i>
                                {t("leads")}
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to="chat-logs"
                                className={({ isActive }) =>
                                    `${isActive
                                        ? "text-primary bg-gray-200 "
                                        : "text-gray-900"
                                    }   group flex items-center gap-x-3 w-full rounded-md p-2 text-sm font-semibold leading-6`
                                }
                            >
                                <i className="pi pi-comments"></i>
                                {t("chat_logs")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="activity max-md:w-full min-h-[calc(100vh-107px)]">
                    <Outlet />
                </div>
            </div>
        </>
    );
}
