import React, { useEffect, useRef, useState } from 'react'
import NavBar from './NavBar'
import { useTranslation } from 'react-i18next'
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { getUser, updatePassword, updateUser } from '../../services/Profile';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';


export default function Setting() {
    const { t } = useTranslation()
    const toast = useRef(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingPassword, setLoadingPassword] = useState(false)
    const [validationErrors, setValidationErrors] = useState({});
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const countries = [
        { name: 'English', value: 'en' },
        { name: 'Français', value: 'fr' },
    ];



    function save() {
        setLoading(true)

        setValidationErrors({});

        let params = {
            name,
            email,
            lang: selectedCountry
        }

        updateUser(params).then((res) => {
            toast.current.show({ severity: 'success', summary: "Success", detail: res.data.message });
        }).catch((error) => {
            if (error.response && error.response.status === 422) {
                setValidationErrors(error.response.data.errors);
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    function savePassword() {
        setLoadingPassword(true)

        setValidationErrors({});

        let params = {
            oldPassword,
            password,
            password_confirmation: cPassword
        }

        updatePassword(params).then((res) => {
            toast.current.show({ severity: 'success', summary: "Success", detail: res.data.message });
            setCPassword('')
            setPassword('')
            setOldPassword('')
        }).catch((error) => {
            if (error.response && error.response.status === 422) {
                setValidationErrors(error.response.data.errors);
            }
        }).finally(() => {
            setLoadingPassword(false)
        })
    }


    useEffect(() => {
        setLoad(true)
        getUser().then((res) => {
            setName(res.data.user.name)
            setEmail(res.data.user.email)
            setSelectedCountry(res.data.user.lang)
            setLoad(false)
        })



    }, [])

    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings')
    }, [])

    return (
        <>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('settings')}</h4>
            </div>

            <Card title={t('profile')}>
                <div>
                    <div>
                        <label className="block text-sm font-medium mb-2">{t('name')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={name} onChange={(e) => setName(e.target.value)} className='w-full' invalid={!!validationErrors.name} />

                        }

                        {!!validationErrors.name && (
                            <small className="text-red-500">{validationErrors.name[0]}</small>
                        )}


                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('email')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <InputText value={email} onChange={(e) => setEmail(e.target.value)} className='w-full ' invalid={!!validationErrors.email} />
                        }

                        {!!validationErrors.email && (
                            <small className="text-red-500">{validationErrors.email[0]}</small>
                        )}

                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('langage_account')}</label>
                        {
                            load ?
                                <Skeleton height="3rem"></Skeleton>
                                :
                                <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} options={countries} optionLabel="name" placeholder="Select a Country"
                                    className="w-full" />
                        }
                    </div>

                    <div className='mt-4'>

                        {
                            !load &&
                            <div className='mt-2 flex justify-end'>
                                <Button label={t('save')} icon="pi pi-check" onClick={save} loading={loading} />
                            </div>
                        }
                    </div>


                </div>
            </Card>

            <Card title={t('change_password')} className='my-6'>
                <div>
                    <div>
                        <label className="block text-sm font-medium mb-2">{t('current_password')}</label>
                        <InputText value={oldPassword} type="password" onChange={(e) => setOldPassword(e.target.value)} className='w-full' invalid={!!validationErrors.oldPassword} />
                        {!!validationErrors.oldPassword && (
                            <small className="text-red-500">{validationErrors.oldPassword[0]}</small>
                        )}
                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('password')}</label>
                        <InputText value={password} type="password" onChange={(e) => setPassword(e.target.value)} className='w-full ' invalid={!!validationErrors.email} />
                        {!!validationErrors.password && (
                            <small className="text-red-500">{validationErrors.password[0]}</small>
                        )}
                    </div>

                    <div className='mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('confirm_password')}</label>
                        <InputText value={cPassword} type="password" onChange={(e) => setCPassword(e.target.value)} className='w-full' />
                    </div>
                    <div className='mt-4'>
                        <div className='mt-2 flex justify-end'>
                            <Button label={t('save')} icon="pi pi-check" onClick={savePassword} loading={loadingPassword} />
                        </div>
                    </div>


                </div>
            </Card>

            <Toast ref={toast} />
        </>
    )
}
