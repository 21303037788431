import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSwitch } from 'primereact/inputswitch';
import { NavLink, useParams } from 'react-router-dom';
import { getStatusAppointment, setStatusAppointment } from '../../../../services/Settings';
import { Toast } from 'primereact/toast';

export default function Appointment() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const toast = useRef(null);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        getStatusAppointment(slug).then(res => {
            setChecked(res.data.appointment == 1 ? true : false);
        })
    }, [])
    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('appointment')
    }, [])

    const handleChange = (value) => {
        setChecked(value)
        setStatusAppointment(slug, value).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
        });
    }


    const title = () => {

        return <div className='flex  gap-3 items-center'>
            <h1>{t('appointment')}</h1>
            <InputSwitch checked={checked} onChange={(e) => handleChange(e.value)} />
        </div>

    }

    return (
        <>
            <Card title={title} className='min-h-[700px]'>
                <p>
                    {t('description_appointment')}
                </p>
                {
                    checked &&
                    <div className='p-4 text-center'>
                        <NavLink to="/dashboard/appointment" className="font-medium hover:no-underline underline ml-2 text-primary cursor-pointer">
                            {t('configure_availability_using_this_link')}
                        </NavLink>
                    </div>
                }

            </Card>
            <Toast ref={toast} />
        </>
    )
}
