import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export default function NotFound() {
    const { t } = useTranslation()

    useEffect(() => {
        document.title = 'DialogEase - 404'
    }, [])

    return (
        <section className="bg-white dark:bg-gray-900 h-screen flex justify-center items-center">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">{t('something_missing')}</p>
                    <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">{t('sorry_we_cant_find_that_page')} </p>
                    <NavLink to={'/'} className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
                        {t('back_to_homepage')}
                    </NavLink>
                </div>
            </div>
        </section>
    )
}
