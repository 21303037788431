import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./authActions";
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';

const Login = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const message = location.state?.message;
    const toast = useRef(null);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [next, setNext] = useState(null);
    const { lang } = useParams();

    useEffect(() => {
        i18n.changeLanguage(lang || 'en');
    }, [lang]);

    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login({ email, password }, navigate, toast, next));
    };

    useEffect(() => {
        if (message) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        }
    }, [message]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const nextParam = searchParams.get("next");
        if (nextParam) {
            setNext(nextParam);
        }
    }, []);

    useEffect(() => {

        const backMessage = sessionStorage.getItem('backMessage')

        if (backMessage) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: backMessage, life: 3000 });
        }
        sessionStorage.removeItem('backMessage')
    }, []);

    useEffect(() => {
        document.title = 'DialogEase - ' + t('sign_in')
    }, [])
    return (
        <div className="flex align-items-center justify-content-center min-h-screen">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-5">
                <div className="flex flex-col items-center mb-5">
                    <img src="/images/logo.png" alt="hyper" className="h-auto w-auto" />
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="block text-900 font-medium mb-2">{t('email')}</label>
                        <InputText id="email" type="text" placeholder={t('email')} className="w-full"
                            invalid={authState.error && authState.error.email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        {authState.error && authState.error.email && (
                            <small className="text-red-500">{authState.error.email[0]}</small>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="block text-900 font-medium mb-2">{t('password')}</label>
                        <InputText id="password" type="password" placeholder={t('password')} className="w-full"
                            value={password}
                            invalid={authState.error && authState.error.password}
                            onChange={(e) => setPassword(e.target.value)} />
                        {authState.error && authState.error.password && (
                            <small className="text-red-500">{authState.error.password[0]}</small>
                        )}
                    </div>

                    <Button label={t('sign_in')} className="w-full my-5" />

                    <div className="flex align-items-center  justify-between flex-col gap-3">
                        <div>
                            <NavLink to={`/${lang || 'en'}/forgot-your-password`} className="font-medium no-underline ml-2 text-primary text-right cursor-pointer">{t('forgot_your_password')}</NavLink>
                        </div>
                        <div>
                            <span>{t('don_t_have_an_account')}</span>
                            <NavLink to={`/${lang || 'en'}/signup${next ? '?next=' + next : ''}`} className="font-medium no-underline ml-2 text-primary text-right cursor-pointer">{t('create_today')}</NavLink>
                        </div>
                    </div>

                </form>
            </div>
            <Toast ref={toast} />
        </div>
    );
};

export default Login;
