import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { Card } from 'primereact/card';
import { AddQA, chatLogWhitId, destroyConversation, fChatLogs, getAi } from '../../../../services/ChatAi';
import DOMPurify from 'dompurify';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import moment from 'moment';
import { useOutletContext } from 'react-router-dom';


export default function ChatLogs() {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = 'DialogEase - ' + t('activity') + ' - ' + t('chat_logs')
  }, [])

  return (
    <>
      <Card className='min-h-[calc(100vh-107px)]'>
        <ChatUI />
      </Card>
    </>
  )
}


const ChatUI = () => {
  const { t } = useTranslation()
  const toast = useRef(null);
  const { setParentDate, parentDate } = useOutletContext();
  const { slug } = useParams()
  const [chat, setChat] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selected, setSelected] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [first, setFirst] = useState(0);
  const [loud, setLoud] = useState(false);
  const [changing, setChanging] = useState(false);
  const [date, setDate] = useState('last_7_days');
  const [filter, setFilter] = useState('all');
  const [hovered, setHovered] = useState(null);
  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentChat, setCurrentChat] = useState({});
  const [visible, setVisible] = useState(false);




  const filters = [
    { name: t('all_conversations'), value: 'all' },
    { name: t('with_leads'), value: 'with_leads' },
    { name: t('without_leads'), value: 'without_leads' },
  ];

  const dates = [
    { name: t('all_time'), value: 'all_time' },
    { name: t('today'), value: 'today' },
    { name: t('yesterday'), value: 'yesterday' },
    { name: t('last_7_days'), value: 'last_7_days' },
    { name: t('last_30_days'), value: 'last_30_days' },
    { name: t('this_month'), value: 'this_month' },
    { name: t('last_month'), value: 'last_month' },
    { name: t('this_year'), value: 'this_year' },
    { name: t("last_year"), value: 'last_year' },
    { name: t('last_24_hours'), value: 'last_24_hours' },
    { name: t('last_48_hours'), value: 'last_48_hours' },
    { name: t('last_7_hours'), value: 'last_7_hours' },
  ];

  function handleChange(event) {
    setChanging(true)
    // setDate(event.value)
    setParentDate(event.value)
    fetchChatLog(event.value, filter)
  }

  function handleChangeFilter(event) {
    setChanging(true)
    setFilter(event.value)
    // fetchChatLog(date, event.value)
    fetchChatLog(parentDate, event.value)
  }



  function fetchChatLog(date, filter, page = 1) {
    fChatLogs(slug, date, filter, page, perPage).then((res) => {
      setChat(res.data.logs);
      setTotal(res.data.total);
      if (res.data.logs.length > 0) {
        setMessages(res.data.messages)
        setCurrentChat(res.data.logs[0])
      }
      setLoud(false)
      setChanging(false)
    });
  }

  useEffect(() => {
    setLoud(true)
    fetchChatLog(parentDate, filter)
    // fetchChatLog(date, filter)
  }, [slug, perPage])

  const onPageChange = (event) => {
    setFirst(event.first);
    // fetchChatLog(date, filter, event.page + 1)
    fetchChatLog(parentDate, filter, event.page + 1)
  };


  const handleSelectChat = (id, index) => {
    setSelected(index);
    setMessages([])
    setCurrentChat(chat.find((c) => c.id === id))
    chatLogWhitId(id).then(res => setMessages(res.data))
  };

  function selectId(id) {
    if (ids.includes(id)) {
      setIds(ids.filter(item => item !== id));
    } else {
      setIds([...ids, id]);
    }
  }


  function deleteChat() {
    setLoading(true);
    destroyConversation({ ids }).then(res => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
      setChat(prevChats => prevChats.filter(chat => !ids.includes(chat.id)));
      fetchChatLog()
      setIds([]);
      setLoading(false);
    })
  }

  return (
    <>
      {
        loud ? <div className='h-15rem flex justify-center items-center'  >
          <ProgressSpinner />
        </div> :

          <div>
            <Toast ref={toast} />
            {/* Top section */}
            <div className="w-full h-32" ></div>

            {/* Main Container */}
            <div className="mt-[-155px]" >

              <div className='flex justify-between items-center flex-wrap-reverse mb-6 gap-2'>

                <div>
                  <Dropdown value={filter} onChange={(e) => handleChangeFilter(e)} options={filters} optionLabel="name"
                    placeholder={filters[0].name} />
                  {
                    ids.length > 0 &&
                    <Button label={t('delete')} severity="danger" loading={loading} className="ml-2" onClick={deleteChat} />
                  }
                </div>


                <Dropdown value={parentDate} onChange={(e) => handleChange(e)} options={dates} optionLabel="name"
                  placeholder={dates[0].name} />

              </div>

              <div className="py-1">

                {chat.length == 0 &&
                  <div className="h-full flex justify-center items-center">
                    <p>{t('no_conversation_found')}</p>
                  </div>}

                <div className="grid">

                  {/* Left Section */}
                  <div className="col-12  md:col-4  flex flex-col">

                    <div className="bg-grey-lighter flex-1 overflow-auto max-h-[740px]">
                      {
                        changing ? <div className='h-15rem flex justify-center items-center'  >
                          <ProgressSpinner />
                        </div> :
                          <div>
                            {
                              chat.map((data, index) =>
                                <div key={index} className={`${index == selected && 'bg-gray-200'}  hover:bg-gray-200 w-full rounded-md flex items-center bg-grey-light cursor-pointer mb-2`}
                                  onMouseEnter={() => setHovered(index)}
                                  onMouseLeave={() => setHovered(null)}
                                >
                                  <div className='flex gap-2 items-center  px-1 w-full'>
                                    <div className="ml-1  surface-100  rounded-md flex items-center" onClick={() => selectId(data.id)} >
                                      <span
                                        className={`m-2 pi ${ids.includes(data.id) ? 'pi-check-circle' : (hovered === index ? 'pi-circle scalein animation-delay-300 animation-duration-500 animation-iteration-infinite' : 'pi-comments')}`}
                                        style={{ fontSize: '1.3rem' }}
                                      ></span>
                                    </div>

                                    <div className="flex-1 border-b border-grey-lighter py-3 msg" onClick={() => { handleSelectChat(data.id, index) }}>
                                      <div className="flex items-bottom justify-between">
                                        <p className="text-grey-darkest text-sm font-bold truncate w-[80%]">{t('conversation')} ({moment(data.created_at).locale('fr').format('lll')})</p>
                                      </div>
                                      <p className="text-grey-dark mt-1 text-sm truncate w-[80%]">{data.latest_message}</p>
                                    </div>

                                  </div>
                                </div>
                              )
                            }
                          </div>
                      }

                    </div>

                  </div>

                  {/* Right Section */}
                  <div className={`md:col-8 col-12`}>
                    {chat.length > 0 &&
                      <ChatTemplate messages={messages} data={currentChat} toast={toast} />}
                  </div>

                </div>
              </div>
            </div>
            <div className='mt-4'>
              {total > 0 && <div className='flex items-center justify-center'>
                <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} />
                <Dropdown value={perPage} onChange={(e) => setPerPage(e.value)} options={[10, 20, 30, 50, 100]} className="me-3" />
                <p>{t('pagination_text', { 'first': first + 1, 'end': first + chat.length, 'total': total })}</p>
              </div>}
            </div>
          </div>
      }
    </>

  );
};


export function ChatTemplate({ messages, data, toast, slug = null }) {
  const op = useRef(null);
  const { t } = useTranslation()
  const params = useParams();
  const resolvedSlug = slug || params.slug;
  const messageEl = useRef(null);
  const [ai, setAi] = useState({});
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [pgSent, setPgSent] = useState('');
  const [pgReceived, setPgReceived] = useState('');
  const [colorSent, setColorSent] = useState('');
  const [colorReceived, setColorReceived] = useState('');
  const [fontSize, setFontSize] = useState();
  const [pgImage, setPgImage] = useState("");
  const [padding, setPadding] = useState();
  const [pgColor, setPgColor] = useState();
  const [checked, setChecked] = useState(false);
  const [loud, setLoud] = useState(false);
  const [dir, setDir] = useState("");
  const [position, setPosition] = useState("center");
  const [visible, setVisible] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");


  useEffect(() => {
    setLoud(true);
    getAi(resolvedSlug).then((res) => {
      setAi(res.data.ai);
      let colors = JSON.parse(res.data.ai.data_colors)
      setPrimaryColor(colors.primaryColor);
      setSecondaryColor(colors.secondaryColor);
      setPgSent(colors.pgSent);
      setPgReceived(colors.pgReceived);
      setColorSent(colors.colorSent);
      setColorReceived(colors.colorReceived);
      setFontSize(colors.fontSize);
      setPgImage(colors.pgImage);
      setChecked(colors.checked)
      setPadding(colors.padding)
      setPgColor(colors.pgColor)
      setDir(colors.dir)
      setPosition(colors.position)
      setLoud(false)
    })
  }, [resolvedSlug])



  function formatDate(date) {

    let language = localStorage.getItem('language') || 'en';
    date = new Date(date);

    const timePart = date.toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit' });
    const datePart = date.toLocaleDateString(language, { day: '2-digit', month: 'long', year: 'numeric' });

    return `${timePart} - ${datePart}`;
  }


  function updateQA(index) {
    setQuestion(messages[index - 1].content);
    setAnswer(messages[index].content);
    setVisible(true)
  }


  function save() {
    let params = {
      question,
      answer
    }
    AddQA(resolvedSlug, params).then(res => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
      setVisible(false);
    })
  }


  useEffect(() => {
    const handleScroll = (event) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    };

    const currentMessageEl = messageEl.current;
    if (currentMessageEl) {
      currentMessageEl.addEventListener('DOMNodeInserted', handleScroll);
    }

    // Cleanup on component unmount
    return () => {
      if (currentMessageEl) {
        currentMessageEl.removeEventListener('DOMNodeInserted', handleScroll);
      }
    };
  }, [messages]);


  function formatContent(content) {
    // Remove 【number:number†source】 pattern
    console.log(content)
    content = content.replace(/【\d+:\d+†source】/g, '');
    content = content.replace(/json/g, '');

    const firstOpen = content.indexOf('{');
    const lastClose = content.lastIndexOf('}');

    let jsonData = null;
    let jsonString = null;

    if (firstOpen !== -1 && lastClose !== -1 && firstOpen < lastClose) {
      jsonString = content.slice(firstOpen, lastClose + 1);
      try {
        jsonData = JSON.parse(jsonString);
      } catch (e) {
        console.error('Erreur lors de l\'analyse du JSON :', e);
      }
    }


    if (jsonData && jsonData.ads && jsonString) {
      console.log(jsonData['ads'])
      // jsonData = jsonData[1].trim();
      const parsedData = jsonData['ads'];

      let jsonToHtml = `<div>`;

      parsedData.forEach(data => {
        jsonToHtml += `<a   class="jsonParent"  ${data.url ? `href="${data.url}" target="_blank"` : ''} >
         ${data.image ? `<img src="${data.image}" />` : ''}
          <div>${data.title}</div>
          </a>`;
      });

      jsonToHtml += `</div>`;

      content = content.replace(jsonString, jsonToHtml);
    }


    // Replace new line characters with <br /> tags
    let formattedContent = content.replace(/\n/g, '<br />');

    // Bold text (double asterisks)
    formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Italic text (single asterisks or underscores)
    formattedContent = formattedContent.replace(/(?:\*{1}(.*?)\*{1}|_{1}(.*?)_{1})/g, (match, p1, p2) => {
      return `<i>${p1 || p2}</i>`;
    });

    // Strikethrough text (double tildes)
    formattedContent = formattedContent.replace(/~~(.*?)~~/g, '<del>$1</del>');

    // Links ([link text](URL))
    formattedContent = formattedContent.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a class="a-link" href="$2" target="_blank">$1</a>');

    // Inline code (backticks)
    formattedContent = formattedContent.replace(/`(.*?)`/g, '<code>$1</code>');

    // Sanitize the formatted content to prevent XSS attacks
    return DOMPurify.sanitize(formattedContent, {
      ADD_ATTR: ['target']
    });
  }


  let style = `

                .a-link {
                  color: blue; /* Change the color of the link */
                text-decoration: underline; /* Underline the link */
}

                /* Add a hover effect */
                .a-link:hover {
                  color: darkblue; /* Darken the color on hover */
}

                /* Optional: Indicate that the link opens in a new tab */
                .a-link::after {
                  content: ' ↗'; /* Add a small arrow icon */
                font-size: smaller; /* Make the arrow smaller */
                color: gray; /* Change the arrow color */
}

                .page {
                  width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                   direction: ${dir};
}

                .marvel-device .screen {
                  text - align: left;
}

                .screen-container {
                  height: 100%;
}



                /* Chat */

                .chat {
                  height: calc(100% - 69px);
}

                .chat-container {
                  height: 100%;
                max-width:445px;
}

.jsonParent {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	background-color: aliceblue;
	border-radius: 5px;
  min-height: 60px;
}

.jsonParent > img {
	height: 90px;
	width: 90px;
	margin-right: 10px;
	border-radius: 5px;
}

                /* User Bar */

                .user-bar {
                  height: 55px;
                background: #${primaryColor};
                color: #${secondaryColor};
                padding: 0 8px;
                font-size: 24px;
                position: relative;
                z-index: 1;
                border-radius: 5px 5px 0 0;
                  display:flex;
  align-items: start;
}

                .user-bar:after {
                  content: "";
                display: table;
                clear: both;
}

                .user-bar div {
                  float: left;
                transform: translateY(-50%);
                position: relative;
                top: 50%;
}

                .user-bar .actions {
                  float: right;
                margin: 0 0 0 20px;
}

                .user-bar .actions.more {
                  margin: 0 12px 0 32px;
}

                .user-bar .actions.attachment {
                  margin: 0 0 0 30px;
}

                .user-bar .actions.attachment i {
                  display: block;
                transform: rotate(-45deg);
}

                .user-bar .avatar {
                  margin: 0 0 0 5px;
                width: 36px;
                height: 36px;
}

                .user-bar .avatar img {
                  border - radius: 50%;
                box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
                display: block;
                width: 100%;
}

                .user-bar .name {
                  font-size: 17px;
                font-weight: 600;
                text-overflow: ellipsis;
                letter-spacing: 0.3px;
                margin: 0 0 0 8px;
                overflow: hidden;
                white-space: nowrap;
                width: 200px;
}

                .user-bar .status {
                  display: block;
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 0;
}

                /* Conversation */

                .conversation {
                  height:  calc(100vh - 250px);
                position: relative;
                background: ${checked ? '#' + pgColor : 'url(' + pgImage + ')'};
                   background-size: cover;
                z-index: 0;
                border-radius: 0 0 5px 5px;
}

                .conversation ::-webkit-scrollbar {
                  transition: all .5s;
                width: 5px;
                height: 1px;
                z-index: 10;
}

                .conversation ::-webkit-scrollbar-track {
                  background: transparent;
}

                .conversation ::-webkit-scrollbar-thumb {
                  background: #b3ada7;
}

                .conversation .conversation-container {
                  height: 100%;
                box-shadow: inset 0 10px 10px -10px #000000;
                overflow-x: hidden;
                padding: 0 16px;
                margin-bottom: 5px;
}

                .conversation .conversation-container:after {
                  content: "";
                display: table;
                clear: both;
}

                /* Messages */

                .message {
                  clear: both;
                line-height: 1.5rem;
                font-size: 15px;
                padding: ${padding}px;
                position: relative;
                margin: 10px 0;
                max-width: 85%;
                word-wrap: break-word;
                font-size:${fontSize}px
}

                .message:after {
                  position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
}




                .message:first-child {
                  margin: 16px 0 8px;
}
.message.receivedltr {
  background: #${pgReceived};
  color:#${colorReceived};
  border-radius: 0px 5px 5px 5px;
  float: left;
}



.message.receivedltr:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #${pgReceived} transparent transparent;
  top: 0;
  left: -10px;
}

.message.sentltr {
  background: #${pgSent};
  color:#${colorSent};
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sentltr:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #${pgSent};
  top: 0;
  right: -10px;
}


.message.receivedrtl {
   background: #${pgReceived};
  color:#${colorReceived};
  border-radius: 5px 0px 5px 5px;
  float: right;
}
.message.receivedrtl:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #${pgReceived};
  top: 0;
  right: -10px;
}

.message.sentrtl {
  background: #${pgSent};
  color:#${colorSent};
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.sentrtl:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #${pgSent} transparent transparent;
  top: 0;
  left: -10px;
}

                /* Compose */

                .conversation-compose {
                  display: flex;
                flex-direction: row;
                align-items: flex-end;
                overflow: hidden;
                height: 50px;
                width: 100%;
                z-index: 2;
}

                .conversation-compose div,
                .conversation-compose input {
                  background: #fff;
                height: 100%;
}

                .conversation-compose .emoji {
                  display: flex;
                align-items: center;
                justify-content: center;
                background: white;
                border-radius: 5px 0 0 5px;
                flex: 0 0 auto;
                margin-left: 8px;
                width: 5px;
}

                .conversation-compose .input-msg {
                  border: 0;
                flex: 1 1 auto;
                font-size: 16px;
                margin: 0;
                outline: none;
                min-width: 50px;
}




                .conversation-compose .send {
                  background: transparent;
                border: 0;
                cursor: pointer;
                flex: 0 0 auto;
                margin-left: 8px;
                margin-right: 8px;
                padding: 0;
                position: relative;
                outline: none;
}

                .conversation-compose .send .circle {
                  background: #${primaryColor};
                border-radius: 50%;
                color: #${secondaryColor};
                position: relative;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
}



                `;

  return (
    <>
      {loud ? <div className='h-15rem flex justify-center items-center'  >
        <ProgressSpinner />
      </div> : <>
        <style>{style}</style>
        <div className="page">
          <div className="screen w-full flex justify-center">
            <div className="screen-container">
              <div className="chat">
                <div className="chat-container max-md:!w-dvw md:w-[445px] max-w-[445px] ">
                  <div className="user-bar">
                    <div className="avatar">
                      <img src={ai.image} alt="Avatar" />
                    </div>
                    <div className="name">
                      <span>{ai.display_name ?? ai.name}</span>
                    </div>

                    <div className={` absolute ${dir == 'ltr' ? 'right-3' : 'left-3'} `} onClick={(e) => op.current.toggle(e)} >
                      <i className={`pi pi-info-circle cursor-pointer`} style={{ fontSize: '1.5rem' }}></i>
                    </div>

                  </div>
                  <div className="conversation">
                    <div className="conversation-container">
                      {
                        messages.length ?
                          messages.map((msg, i) => <div key={i}>
                            {
                              msg.role === 'user' ?
                                <div className={`message ${msg.role === 'user' ? 'sent' + dir : 'received' + dir}`}>
                                  <span dangerouslySetInnerHTML={{ __html: formatContent(msg.content) }} />
                                </div> :
                                <div className='static '>
                                  <div className={`message ${msg.role === 'user' ? 'sent' + dir : 'received' + dir}`}>
                                    <span dangerouslySetInnerHTML={{ __html: formatContent(msg.content) }} />
                                    {
                                      i != 0 &&
                                      <span onClick={() => updateQA(i)} className='absolute bottom-0  right-[-25px]'><i className={`pi pi-pen-to-square cursor-pointer`} style={{ fontSize: '1.2rem' }}></i></span>
                                    }
                                  </div>
                                </div>
                            }
                          </div>
                          ) :

                          <div className='flex justify-center items-center h-full '>
                            <ProgressSpinner strokeWidth="5" />
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <OverlayPanel ref={op} showCloseIcon closeOnEscape >
          <div className="mx-auto flex  flex-row justify-between">
            <h4 className="text-xl font-bold">{t('conversation_started_on')}</h4>
          </div>

          <p className='my-2'>
            {formatDate(data.created_at)}
          </p>

          <Link to={data.url} target='_blank' className='text-primary hover:underline'>
            {data.url}
          </Link>

        </OverlayPanel>

        <Dialog header={t('question_answer')} visible={visible} style={{ width: '30vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
          <div className="mt-2">
            <div className="w-full">
              <div className='flex justify-between items-end mb-2'>
                <label htmlFor={"Question"} className="block text-900 font-medium mb-2">
                  {t('question')}
                </label>
              </div>
              <InputTextarea
                className='w-full'
                id={"Question"}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={t('question')}
                rows={1}
              />
            </div>
            <div className="w-full mt-3">
              <label htmlFor={"Answer"} className="block text-900 font-medium mb-2">
                {t('answer')}
              </label>
              <InputTextarea
                className='w-full'
                id={'Answer'}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                placeholder={t('answer')}
                rows={3}
              />
            </div>

            <div>
              <Button label={t('save')} onClick={save} className='mt-3 w-full' />
            </div>

          </div>
        </Dialog>

      </>}
    </>
  );
}
