import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'primereact/card';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { apiGeneral, editName } from '../../../../services/Settings';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { deleteChatAi } from '../../../../services/ChatAi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'primereact/skeleton';



export default function General() {
    const { t } = useTranslation()
    const { slug } = useParams();
    const [isCopied, setIsCopied] = useState(false);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [load, setLoad] = useState(false)
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        setLoad(true)
        apiGeneral(slug).then((response) => {
            setName(response.data.ai.name)
            setLoad(false)
        })
    }, [slug])

    function save() {
        setLoading(true)
        editName(slug, { name }).then((response) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
            setLoading(false)
        })
    }

    const accept = () => {
        setDeleting(true); // Start the deleting process
        deleteChatAi(slug)
            .then((res) => {
                // Show success message on successful deletion
                toast.current.show({ severity: 'success', summary: res.data.message, life: 3000 });

                // Set deleting to false after the operation completes
                setDeleting(false);

                // Navigate to the desired route with message
                navigate('/dashboard/chatbots', { state: { message: res.data.message } });
            })
            .catch((error) => {
                // Handle error, if deletion fails
                toast.current.show({ severity: 'error', summary: 'Failed to delete', detail: error.message, life: 3000 });

                // Ensure deleting is set back to false in case of an error
                setDeleting(false);
            });

    }

    function Delete() {
        confirmDialog({
            message: t('are_you_sure_you_want_to_delete_your_chatbot'),
            header: t('delete_chatbot'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'accept',
            acceptClassName: 'p-button-danger',
            accept,
            reject: () => { }
        });
    }


    const copyToClipboard = () => {
        navigator.clipboard.writeText(slug).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        });
    };


    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('general')
    }, [])
    
    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Card title={t('general')}>
                <div>
                    <label className="block text-sm font-medium mb-1">{t('chatbot_id')}</label>
                    <div className='flex  items-center gap-2'>
                        <span className='font-semibold'>{slug}</span>
                        {isCopied ?
                            <Button size='small' icon="pi pi-check" outlined severity="success" /> :
                            <Button size='small' icon="pi pi-copy" outlined severity="secondary" onClick={copyToClipboard} />}

                    </div>
                </div>
                <div className='mt-6'>
                    <label className="block text-sm font-medium mb-2">{t('name')}</label>
                    {
                        load ?
                            <Skeleton height="3rem"></Skeleton>
                            :
                            <InputText value={name} onChange={(e) => setName(e.target.value)} className='w-full ' />
                    }
                </div>
                <div className='mt-2 flex justify-end'>
                    <Button icon="pi pi-check" label={t('save')} onClick={save} loading={loading} />
                </div>

            </Card>


            <div className="relative my-10"><div className="absolute inset-0 flex items-center">
                <span className="w-full border-t"></span>
            </div>
                <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-[#f4f5f9] px-2 font-bold text-red-500">{t('danger_zone')}</span>
                </div>
            </div>

            <Card title={() => <span className='text-red-500'>{t('delete_chatbot')}</span>} className='cardRed'>
                <p>{t('once_you_delete_your_chatbot')}</p>
                <p>{t('all_your_uploaded_data_will_be_deleted')} <span className='font-bold'>{t('this_action_is_not_reversible')}</span></p>

                <div className='mt-2 flex justify-end'>
                    <Button label={t('delete')} icon="pi pi-trash" severity="danger" onClick={Delete} loading={deleting} />
                </div>
            </Card>


        </>
    )
}
