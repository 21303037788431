import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const apiGeneral = async (slug) => {
    await Csrf.getCookie();
    return await Api.get("/general/" + slug);
}

export const editName = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post("/general/" + slug, params);
}


export const apiAI = async (slug) => {
    await Csrf.getCookie();
    return await Api.get("/ai/" + slug);
}

export const editInstructions = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post("/ai/" + slug, params);
}

export const chat_interface = async (slug) => {
    await Csrf.getCookie();
    return await Api.get("/chat-interface/" + slug);
}

export const edit_chat_interface = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post("/chat-interface/" + slug, params);
}

export const FModules = async () => {
    await Csrf.getCookie();
    return await Api.get("/modules");
}

export const FUploadProfile = async (slug, params) => {
    await Csrf.getCookie();
    return await Api.post("/uploadProfile/" + slug, params);
}

export const getStatusAppointment = async (slug) => {
    await Csrf.getCookie();
    return await Api.get("/getStatusAppointment/" + slug);
}


export const setStatusAppointment = async (slug, value) => {
    await Csrf.getCookie();
    return await Api.put("/setStatusAppointment/" + slug, { value });
}

export const changeLang = async (lang) => {
    await Csrf.getCookie();
    return await Api.put("/changeLang/", { lang });
}
