import Api from '../services/Api';
import Csrf from '../services/Csrf';



export const getUsers = async (page, abortController) => {
    await Csrf.getCookie();
    return await Api.get(`/users`, {
        params: {
            page
        }
    }, {
        signal: abortController.signal
    });
}


export const getTransactions = async () => {
    await Csrf.getCookie();
    return await Api.get(`/transactions`);
}


export const saveTransactions = async (transactions) => {
    await Csrf.getCookie();
    return await Api.post(`/transactions`, { transactions });
}


export const getColors = async () => {
    await Csrf.getCookie();
    return await Api.get(`/colors`);
}

export const saveColors = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/colors`, params);
}


export const saveFAQ = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/faq`, params);
}

export const getFAQ = async () => {
    await Csrf.getCookie();
    return await Api.get(`/faq`);
}

export const getTickets = async (page) => {
    await Csrf.getCookie();
    return await Api.get(`/ticket`, {
        params: {
            page
        }
    });
}

export const getTicket = async (id) => {
    await Csrf.getCookie();
    return await Api.get("/ticket/" + id);
}

export const deleteUser = async (id) => {
    await Csrf.getCookie();
    return await Api.delete("/users/" + id);
}

export const loginUser = async (id) => {
    await Csrf.getCookie();
    return await Api.post("/loginUser/" + id);
}

export const generateToken = async () => {
    await Csrf.getCookie();
    return await Api.post("/token");
}