import React, { useEffect, useRef, useState } from 'react'
import Layout from './Layout'
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';
import { getTickets } from '../../../services/Ticket';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export default function Tickets() {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [tickets, setTickets] = useState([])
    const navigate = useNavigate();


    useEffect(() => {
        document.title = 'DialogEase - ' + t('tickets')
        getTickets().then(res => {
            setTickets(res.data.tickets)
        })

    }, [])

    const FormatDate = (date) => {
        const language = localStorage.getItem('language');
        dayjs.locale(language);
        const formattedDate = dayjs(date).format(language === 'fr' ? 'DD/MM/YYYY  HH:mm' : 'MM/DD/YYYY hh:mm a');
        return formattedDate;
    };

    return (
        <Layout>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('tickets')}</h4>
                <NavLink to={'/dashboard/help'} className="p-button font-bold ">{t('open_a_new_ticket')}</NavLink>
            </div>

            <div>
                {
                    tickets.map(ticket =>
                        <div key={ticket.id} className='p-card p-4 grid justify-between items-center cursor-pointer mb-4' onClick={() => navigate(`/dashboard/tickets/${ticket.id}`)}>
                            <div className='flex items-center gap-2 col-3'>
                                <span className='text-primary bg-transparent border border-[var(--primary-color)] py-2 px-4 rounded-md font-bold'>#{ticket.id}</span>
                                <h4 className='text-lg font-bold'>
                                    {FormatDate(ticket.created_at)}
                                </h4>
                            </div>

                            <div className=' border-l-2 col'>
                                <h4 className='text-lg font-bold text-primary ml-3 capitalize'>{ticket.subject}</h4>
                            </div>

                            <div className='flex col-1 justify-end '>

                                {
                                    ticket.state === 'open' ? <>
                                        <Tooltip target=".toll" className='tollBg' />
                                        <span className='toll  bg-[var(--primary-color)] p-2 rounded-full' data-pr-tooltip={t('ticket_is_open')} data-pr-position="top"></span>
                                    </> :
                                        <>
                                          <Tooltip target=".toll"  />
                                          <span className=' toll bg-gray-500 p-2 rounded-full' data-pr-tooltip={t('if_you_want_to_open_a_ticket_send_a_message')} data-pr-position="top"></span>
                                        </>
                                }

                            </div>
                        </div>
                    )
                }




            </div>

        </Layout>
    )
}


