import Api from '../services/Api';
import Csrf from '../services/Csrf';


export const storeCustomAction = async (params) => {
    await Csrf.getCookie();
    return await Api.post(`/custom-action`, params);
}

export const getActions = async (slug) => {
    await Csrf.getCookie();
    return await Api.get(`/custom-action?slug=${slug}`);
}


export const updateCustomAction = async (id, params) => {
    await Csrf.getCookie();
    return await Api.put(`/custom-action/${id}`, params);
}

export const deleteCustomAction = async (id) => {
    await Csrf.getCookie();
    return await Api.delete(`/custom-action/${id}`);
}