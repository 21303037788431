import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { PayPalScriptProvider, usePayPalScriptReducer, PayPalButtons } from '@paypal/react-paypal-js';
import { addExtraMessages, cancelSubscription, editAbonnementId, getSubscription } from '../../../services/Subscription';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { Dialog } from 'primereact/dialog';

export default function Plans() {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);
    const [currentMessages, setCurrentMessages] = useState(0);
    const [key, setKey] = useState(0);
    const client_id = window.PAYPAL_CLIENT_ID

    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('plans');
    }, [])

    return (
        <>

            <div className="p-card p-3">
                <div className="text-900 font-bold text-6xl mb-4 text-center">{t('pricing_plans')}</div>
                <div className="text-700 text-xl  text-center line-height-3">{t('description_pricing_plans')}</div>
                <div className="flex justify-content-center my-5 gap-3">
                    <span onClick={() => setChecked(false)} className={`select-none cursor-pointer ${!checked && 'text-primary  font-bold'}`}>{t('monthly')}</span>
                    <InputSwitch className='sending_type' checked={checked} onChange={(e) => setChecked(e.value)} />
                    <span onClick={() => setChecked(true)} className={`select-none cursor-pointer ${checked && 'text-primary  font-bold'}`}>{t('yearly')}</span>
                </div>

                {
                    checked ?
                        <Year setCurrentMessages={setCurrentMessages} key={key} /> :
                        <Month setCurrentMessages={setCurrentMessages} clientId={client_id} />
                }

            </div >

            <Messages currentMessages={currentMessages} clientId={client_id} />

        </>
    )
}


function Month({ setCurrentMessages, clientId }) {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [status, setStatus] = useState('');
    const [paypal, setPaypal] = useState('');
    const [idStarter, setIdStarter] = useState('');
    const [idPremium, setIdPremium] = useState('');
    const [prixFree, setPrixFree] = useState('');
    const [prixStarter, setPrixStarter] = useState('');
    const [prixPremium, setPrixPremium] = useState('');
    const [visible, setVisible] = useState(false);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [currentPlanName, setCurrentPlanName] = useState(null);

    function handlePay(plan, name) {
        setCurrentPlan(plan);
        setCurrentPlanName(name);
        setVisible(true);
    }

    function handlePayClose() {
        setCurrentPlan(null);
        setCurrentPlanName(null);
        setVisible(false);
    }

    function getSub() {
        getSubscription().then(res => {
            setStatus(res.data.status);
            setPaypal(res.data.paypal);
            setIdStarter(res.data.month.id_starter);
            setIdPremium(res.data.month.id_premium);
            setPrixFree(res.data.month.prix_free);
            setPrixStarter(res.data.month.prix_starter);
            setPrixPremium(res.data.month.prix_premium);
            setCurrentMessages(res.data.messages);
        })
    }

    useEffect(() => {
        getSub();
    }, []);


    function cancel() {
        cancelSubscription().then(res => {
            toast.current.show({ severity: 'success', summary: 'Info', detail: t('you_have_successfully_deactivated_your_subscription') });
            getSub();
        })
    }

    const freeJson = t('free_plan').replace('{', '[').replace('}', ']');
    const starterJson = t('starter_plan').replace('{', '[').replace('}', ']');
    const premiumJson = t('premium_plan').replace('{', '[').replace('}', ']');

    return <>
        <Toast ref={toast} />
        <div className="grid">

            <div className="col-12 lg:col-4">
                <div className="p-3 h-full">
                    <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: '6px' }}>
                        <div className="text-900 font-medium text-xl mb-2">{t('free')}</div>
                        <div className="text-600">{t('plan_description_free')}</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                            <span className="font-bold text-2xl text-900">{prixFree}</span>
                            <span className="ml-2 font-medium text-600">{t('per_month')}</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">

                            {
                                JSON.parse(freeJson).map((item, index) => (
                                    <li key={index} className="flex align-items-center mb-3">
                                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span>{item}</span>
                                    </li>
                                ))
                            }
                        </ul>

                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                        {
                            paypal !== '' ?
                                <span className="p-3 w-full mt-auto h-[45px] mb-1" outlined="true" ></span> :
                                <>
                                    <Button label={t('current_plan')} className="p-3 w-full mt-auto h-[45px] mb-1 cursor-not-allowed" outlined="true" />
                                </>
                        }

                    </div>
                </div>
            </div >

            <div className="col-12 lg:col-4">
                <div className="p-3 h-full">
                    <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: '6px' }}>
                        <div className="text-900 font-medium text-xl mb-2">{t('starter')}</div>
                        <div className="text-600">{t('plan_description_starter')}</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                            <span className="font-bold text-2xl text-900">{prixStarter}</span>
                            <span className="ml-2 font-medium text-600">{t('per_month')}</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">
                            {
                                JSON.parse(starterJson).map((item, index) => (
                                    <li key={index} className="flex align-items-center mb-3">
                                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span>{item}</span>
                                    </li>
                                ))
                            }
                        </ul>

                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />

                        {
                            paypal !== idStarter ?
                                <Button label={t('upgrade_to_this_plan')} className="p-3 w-full mt-auto h-[45px] mb-1" onClick={() => handlePay(idStarter, t('starter'))} /> :
                                <Button label="Cancel" className="p-3 w-full mt-auto h-[45px] mb-1" outlined="true" onClick={cancel} />
                        }

                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-4">
                <div className="p-3 h-full">
                    <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: '6px' }}>
                        <div className="text-900 font-medium text-xl mb-2">{t('premium')}</div>
                        <div className="text-600">{t('plan_description_premium')}</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                            <span className="font-bold text-2xl text-900">{prixPremium}</span>
                            <span className="ml-2 font-medium text-600">{t('per_month')}</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">
                            {
                                JSON.parse(premiumJson).map((item, index) => (
                                    <li key={index} className="flex align-items-center mb-3">
                                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span>{item}</span>
                                    </li>
                                ))
                            }
                        </ul>
                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />

                        {
                            paypal !== idPremium ?
                                <Button label={t('upgrade_to_this_plan')} className="p-3 w-full mt-auto h-[45px] mb-1" onClick={() => handlePay(idPremium, t('premium'))} /> :
                                <Button label="Cancel" className="p-3 w-full mt-auto h-[45px] mb-1" outlined="true" onClick={cancel} />
                        }
                    </div>
                </div>
            </div>

        </div>

        <Dialog header={currentPlanName} visible={visible} style={{ width: '30vw' }} onHide={() => { if (!visible) return; handlePayClose(); }}>
            <PayPalSubscription clientId={clientId} currentPlan={currentPlan} cancelSubscription={cancelSubscription} editAbonnementId={editAbonnementId} handlePayClose={handlePayClose} toast={toast} />
        </Dialog>

    </>
}

function Year({ setCurrentMessages, clientId }) {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [status, setStatus] = useState('');
    const [paypal, setPaypal] = useState('');
    const [idStarter, setIdStarter] = useState('');
    const [idPremium, setIdPremium] = useState('');
    const [prixFree, setPrixFree] = useState('');
    const [prixStarter, setPrixStarter] = useState('');
    const [prixPremium, setPrixPremium] = useState('');
    const [visible, setVisible] = useState(false);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [currentPlanName, setCurrentPlanName] = useState(null);



    function handlePay(plan, name) {
        setCurrentPlan(plan);
        setCurrentPlanName(name);
        setVisible(true);

    }

    function handlePayClose() {
        setCurrentPlan(null);
        setCurrentPlanName(null);
        setVisible(false);
    }


    function getSub() {
        getSubscription().then(res => {
            setStatus(res.data.status);
            setPaypal(res.data.paypal);
            setIdStarter(res.data.year.id_starter);
            setIdPremium(res.data.year.id_premium);
            setPrixFree(res.data.year.prix_free);
            setPrixStarter(res.data.year.prix_starter);
            setPrixPremium(res.data.year.prix_premium);
            setCurrentMessages(res.data.messages);
        })
    }

    useEffect(() => {
        getSub();
    }, []);



    function cancel() {
        cancelSubscription().then(res => {
            toast.current.show({ severity: 'success', summary: 'Info', detail: t('you_have_successfully_deactivated_your_subscription') });
            getSub();
        })
    }

    const freeJson = t('free_plan').replace('{', '[').replace('}', ']');
    const starterJson = t('starter_plan').replace('{', '[').replace('}', ']');
    const premiumJson = t('premium_plan').replace('{', '[').replace('}', ']');

    return <>
        <Toast ref={toast} />
        <div className="grid">

            <div className="col-12 lg:col-4">
                <div className="p-3 h-full">
                    <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: '6px' }}>
                        <div className="text-900 font-medium text-xl mb-2">{t('free')}</div>
                        <div className="text-600">{t('plan_description_free')}</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                            <span className="font-bold text-2xl text-900">{prixFree}</span>
                            <span className="ml-2 font-medium text-600">{t('per_year')}</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">

                            {
                                JSON.parse(freeJson).map((item, index) => (
                                    <li key={index} className="flex align-items-center mb-3">
                                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span>{item}</span>
                                    </li>
                                ))
                            }
                        </ul>

                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                        {
                            paypal !== '' ?
                                <Button label={t('upgrade_to_this_plan')} className="p-3 w-full mt-auto h-[45px] mb-1 cursor-not-allowed" outlined="true" /> :
                                <Button label={t('current_plan')} className="p-3 w-full mt-auto h-[45px] mb-1 cursor-not-allowed" outlined="true" />

                        }

                    </div>
                </div>
            </div >

            <div className="col-12 lg:col-4">
                <div className="p-3 h-full">
                    <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: '6px' }}>
                        <div className="text-900 font-medium text-xl mb-2">{t('starter')}</div>
                        <div className="text-600">{t('plan_description_starter')}</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                            <span className="font-bold text-2xl text-900">{prixStarter}</span>
                            <span className="ml-2 font-medium text-600">{t('per_year')}</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">
                            {
                                JSON.parse(starterJson).map((item, index) => (
                                    <li key={index} className="flex align-items-center mb-3">
                                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span>{item}</span>
                                    </li>
                                ))
                            }
                        </ul>

                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />

                        {
                            paypal !== idStarter ?
                                <Button label={t('upgrade_to_this_plan')} className="p-3 w-full mt-auto h-[45px] mb-1" onClick={() => handlePay(idStarter, t('starter'))} /> :
                                <Button label="Cancel" className="p-3 w-full mt-auto h-[45px] mb-1" outlined="true" onClick={cancel} />
                        }

                    </div>
                </div>
            </div >

            <div className="col-12 lg:col-4">
                <div className="p-3 h-full">
                    <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: '6px' }}>
                        <div className="text-900 font-medium text-xl mb-2">{t('premium')}</div>
                        <div className="text-600">{t('plan_description_premium')}</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                            <span className="font-bold text-2xl text-900">{prixPremium}</span>
                            <span className="ml-2 font-medium text-600">{t('per_year')}</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">
                            {
                                JSON.parse(premiumJson).map((item, index) => (
                                    <li key={index} className="flex align-items-center mb-3">
                                        <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                        <span>{item}</span>
                                    </li>
                                ))
                            }
                        </ul>
                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />

                        {
                            paypal !== idPremium ?
                                <Button label={t('upgrade_to_this_plan')} className="p-3 w-full mt-auto h-[45px] mb-1" onClick={() => handlePay(idPremium, t('premium'))} /> :
                                <Button label="Cancel" className="p-3 w-full mt-auto h-[45px] mb-1" outlined="true" onClick={cancel} />
                        }
                    </div>
                </div>
            </div>
        </div >

        <Dialog header={currentPlanName} visible={visible} style={{ width: '30vw' }} onHide={() => { if (!visible) return; handlePayClose(); }}>
            <PayPalSubscription clientId={clientId} currentPlan={currentPlan} cancelSubscription={cancelSubscription} editAbonnementId={editAbonnementId} handlePayClose={handlePayClose} toast={toast} />
        </Dialog>

    </>
}

const Messages = ({ currentMessages, clientId }) => {
    const { t } = useTranslation();
    const [messages, setMessages] = useState(500);
    const [amount, setAmount] = useState(0.00);
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [key, setKey] = useState(100);
    const [pay, setPay] = useState(false);

    function changeMessages(e) {
        let value = e.target.value
        if (value > 10000) {
            value = 10000;
        } else if (value < 500) {
            value = 500;
        }
        setMessages(value);
    }

    const createOrder = (amount) => (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: amount,
                    },
                    description: "Dynamic payment based on messages",
                },
            ],
        });
    };

    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [pay])

    useEffect(() => {
        const calculatedAmount = messages * 0.008;
        setAmount(calculatedAmount.toFixed(2));
    }, [messages])


    const initialOptions = useMemo(() => ({
        'client-id': clientId,
        vault: true,
        intent: 'capture',
    }), [clientId]);

    const buttonStyles = useMemo(() => ({
        layout: 'vertical',
        color: 'gold',
        shape: 'rect',
        tagline: false,
    }), []);

    const title = () => {
        return (
            <div className='flex gap-2 items-center'>
                <h1 className='text-900 font-bold text-2xl'>{t('extra_message_credits')}</h1>
                <Tag severity={currentMessages >= 1000 ? "success" : currentMessages >= 100 ? "warning" : "danger"} value={t('messages') + currentMessages}></Tag>
            </div>)
    }

    return (<>
        <Card className='mt-4' title={title}>
            <p className='ms-2'>{t('extra_message_credits_description')}</p>

            <div className='flex justify-end mt-4 items-center'>
                <Button label={t('add_extra_message_credits')} onClick={() => setVisible(true)} />
            </div>


        </Card >

        <Dialog header={t('add_extra_message_credits')} visible={visible} style={{ width: '30vw' }} onHide={() => { if (!visible) return; setVisible(false); setPay(false) }}>

            {
                pay ?
                    <div>
                        <div className='w-full'>
                            <PayPalScriptProvider options={initialOptions}>
                                <div>
                                    <PayPalButtons
                                        key={'capture' + key}
                                        createOrder={createOrder(amount)}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then((details) => {
                                                addExtraMessages({ messages, details }).then(res => {
                                                    toast.current.show({
                                                        severity: 'success', summary: 'Success', detail: res.data.message, life: 3000
                                                    });
                                                    setVisible(false);
                                                })
                                            });
                                        }}
                                        style={buttonStyles}

                                    />
                                </div>
                                <hr className='my-4' />
                                <Button label={t('annuler')} severity='secondary' className='w-full' outlined="true" onClick={() => { setVisible(false); setPay(false) }} />
                            </PayPalScriptProvider>
                        </div>
                    </div> :
                    <div>
                        <div className='flex items-center '>
                            <div className="flex flex-column align-items-center col-10">
                                <InputText value={messages} onChange={changeMessages} keyfilter="pint" className="w-full" />
                                <Slider value={messages} onChange={(e) => setMessages(e.value)} className="w-full" min={500} max={10000} />
                            </div>
                            <div>
                                <p className="text-900 font-bold text-2xl">€{amount}</p>
                            </div>

                        </div>

                        <div className='flex justify-end mt-4'>
                            <Button label={t('valider')} onClick={() => setPay(true)} />
                        </div>
                    </div>
            }

        </Dialog>
        <Toast ref={toast} /></>)
        ;
}


function PayPalSubscription({ clientId, currentPlan, cancelSubscription, editAbonnementId, handlePayClose, toast }) {
    const { t } = useTranslation();
    const initialOptions = useMemo(() => ({
        'client-id': clientId,
        vault: true,
        intent: 'subscription',
    }), [clientId]);

    const buttonStyles = useMemo(() => ({
        layout: 'vertical',
        color: 'gold',
        shape: 'rect',
        tagline: false,
    }), []);

    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                key={`subscription-${currentPlan}`}
                createSubscription={(data, actions) => {
                    return actions.subscription.create({ plan_id: currentPlan });
                }}
                onApprove={(data) => {
                    toast.current.show({ severity: 'success', summary: 'Info', detail: 'You have successfully subscribed!' });
                    cancelSubscription();
                    editAbonnementId(data.subscriptionID, currentPlan);
                    handlePayClose();
                }}
                style={buttonStyles}
            />
            <hr className='my-4' />
            <Button label={t('annuler')} severity='secondary' className='w-full' outlined="true" onClick={handlePayClose} />
        </PayPalScriptProvider>
    );
}