import Api from '../services/Api';
import Csrf from '../services/Csrf';


export const updateAction = async (id, params) => {
    await Csrf.getCookie();
    return await Api.put(`/actions/${id}`, params);
}

export const getAction = async (id) => {
    await Csrf.getCookie();
    return await Api.get(`/actions/${id}`);
}