import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export default function Footer() {
    const { t } = useTranslation()

    return (
        <footer className="bg-[#0e231c] card mt-[4.5rem]">
            <div className="w-full max-w-screen-xl mx-auto p-4 md:py-3">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="/" className="flex items-center mb-3 sm:mb-0 space-x-3 rtl:space-x-reverse">
                        <img src="/images/logo-white.png" className="h-10" alt="chat Ai Logo" />
                    </a>
                    <ul className="flex flex-wrap items-center mb-3 text-sm font-medium text-white sm:mb-0">
                        <li >
                            <NavLink to="/faq" className="hover:underline me-4 md:me-6">{t('faq')}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard/help" className="hover:underline me-4 md:me-6">{t('help')}</NavLink>
                        </li>
                    </ul>
                </div>
                <hr className="my-3 border-white sm:mx-auto  lg:my-3" />
                <span className="block text-sm text-white sm:text-center">
                    © {new Date().getFullYear()} <a href="/" className="hover:underline">Chat AI</a>. {t('all_rights_reserved')}
                </span>
            </div>
        </footer>
    )
}
