const initialState = {
    loading: false,
    user: null,
    error: null,
    customChat: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                loading: false,
                user: action.payload,
                error: null,
            };
        case 'LOGIN_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'LOGOUT_REQUEST':
            return {
                ...state,
                loading: false,
                user: null,
                error: null,
            };

        case 'CUSTOM_CHAT':
            return {
                ...state,
                customChat: action.payload
            };
        default:
            return state;
    }
};

export default authReducer;
