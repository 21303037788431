import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getTickets } from '../../../services/Admin';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { Paginator } from 'primereact/paginator';


export default function Tickets() {
    const { t } = useTranslation()
    const [tickets, setTickets] = useState([]);
    const navigate = useNavigate();
    const [perPage, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);


    function fetchTickets(page = 1) {
        getTickets(page).then(res => {
            setTickets(res.data.tickets)
            setPerPage(res.data.per_page);
            setTotal(res.data.total);
        })
    }

    useEffect(() => {
        fetchTickets();
    }, [])




    const onPageChange = (event) => {
        setFirst(event.first);
        fetchTickets(event.page + 1)
    };

    const severityBody = (data) => {
        let severity;
        switch (data.severity) {
            case 'low':
                severity = 'info';
                break;
            case 'high':
                severity = 'warning';
                break;
            case 'urgent':
                severity = 'danger';
                break;
            default:
                severity = "success";
                break;
        }
        return <Tag value={data.severity} severity={severity}></Tag>;
    }

    const problemBody = (data) => {
        return t(data.problem)
    }

    const stateBody = (data) => {
        let severity;
        switch (data.state) {
            case 'closed':
                severity = 'secondary';
                break;
            default:
                severity = "success";
                break;
        }
        return <Badge value={t(data.state)} severity={severity}></Badge>;
    }



    const actions = data => (
        <div className='flex gap-1'>
            <Button icon="pi pi-eye" className="p-button-rounded" severity="info" aria-label="View" onClick={() => navigate('/admin/tickets/' + data.id)} />
        </div>
    );

    return (
        <>
            <div className="flex  justify-between px-1 py-5">
                <h4 className="text-3xl font-bold my-1">{t('tickets')}</h4>
            </div>

            <div>
                <DataTable value={tickets} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="id" header={t('id_ticket')}></Column>
                    <Column field="subject" header={t('subject')}></Column>
                    <Column field="user.name" header={t('client')}></Column>
                    <Column field="severity" header={t('severity')} body={severityBody}></Column>
                    <Column field="problem" header={t('problem')} body={problemBody}></Column>
                    <Column field="state" header={t('state')} body={stateBody}></Column>
                    <Column body={actions} header={t('actions')} />
                </DataTable>
            </div>
            <div className='mt-4'>
                <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} />
            </div>
        </>
    )
}
