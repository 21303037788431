
import React, { useEffect, useRef, useState } from 'react'
import Layout from './Layout'
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { NavLink, useParams } from 'react-router-dom';
import { addMessageToTicket, getTicket } from '../../../services/Ticket';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { Avatar } from 'primereact/avatar';
import { ScrollPanel } from 'primereact/scrollpanel';


dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export default function Chat() {
    const { t } = useTranslation();
    const { id } = useParams();
    const userId = useSelector(state => state.auth.user.id)
    const toast = useRef(null);
    const [messages, setMessages] = useState([])
    const [subject, setSubject] = useState('')
    const navigate = useNavigate();
    const [description, setDescription] = useState('');


    function fetchTicket() {
        getTicket(id).then((res) => {
            setMessages(res.data.messages)
            setSubject(res.data.subject)
            document.title = 'DialogEase - ' + t('tickets') + ' - ' + res.data.subject
        }).catch((error) => {
            if (error.response && error.response.status === 404) {
                navigate('/dashboard/tickets');
            }
        })
    }

    useEffect(() => {
        fetchTicket();
    }, [])

    const FormatDate = (date) => {
        const language = localStorage.getItem('language');
        dayjs.locale(language);
        const formattedDate = dayjs(date).format(language === 'fr' ? 'DD/MM/YYYY  HH:mm' : 'MM/DD/YYYY  hh:mm a');
        return formattedDate;
    };

    function save() {
        const trimmedDescription = description.trim();
        if (!trimmedDescription) {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('please_enter_description') });
            return;
        }


        let params = {
            description: description,
            ticket_id: id
        }

        addMessageToTicket(params).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
            setDescription('');
            fetchTicket();
        })

    }

    return (
        <Layout>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold capitalize">{t('subject')}: {subject}</h4>
                <NavLink to={'/dashboard/tickets'} className="p-button font-bold ">{t('tickets')}</NavLink>
            </div>
            <div className=' space-y-3'>
                <ScrollPanel style={{ width: '100%', height: '700px' }} aria-controls='down arrow'>
                    <div className=' space-y-3'>
                {
                    messages.map((msg, index) =>
                        <div key={index} className='p-card'>
                            <div className={`${msg.sender_id == userId ? 'bg-slate-600' : 'bg-[var(--primary-color)]'} text-lg font-bold  text-white p-3 rounded-t-md flex  justify-between`}>
                                {/* <span>{msg.sender_id == userId ? t('you') : t('support_team')}</span> */}

                                {
                                    msg.sender_id == userId ? <div className='flex items-center gap-2'>
                                        <Avatar icon="pi pi-user" size="xlarge" shape="circle" style={{ backgroundColor: 'transparent', color: '#ffffff', border:'2px solid #ffffff' }} />
                                        <span>{t('you')}</span>
                                    </div> :
                                        <div className='flex items-center gap-2'>
                                            <Avatar image="/images/icon-white.png" size="xlarge" shape="circle" style={{ border: '2px solid #ffffff' }} />
                                            <span>{t('support_team')}</span>
                                        </div>
                                }

                                <span>{FormatDate(msg.created_at)}</span>
                            </div>
                            <div className='p-4'>
                                <span dangerouslySetInnerHTML={{ __html: msg.description }} />
                            </div>
                        </div>
                    )
                        }
                    </div>
                </ScrollPanel>

                <div className='p-card mt-5'>
                    <div className={`bg-slate-600 text-lg font-bold  text-white p-3 rounded-t-md flex  justify-between`}>
                        <div className='flex items-center gap-2'>
                            <Avatar icon="pi pi-user" size="xlarge" shape="circle" style={{ backgroundColor: 'transparent', color: '#ffffff', border: '2px solid #ffffff' }} />
                            <span>{t('you')}</span>
                        </div>
                        <span>{t('new')}</span>
                    </div>
                    <div className='p-0'>
                        <Editor value={description} onTextChange={(e) => setDescription(e.htmlValue)} style={{ height: '320px' }} />
                    </div>
                    <div className='p-2 flex justify-end'>
                        <Button label={t('submit')} onClick={save} />
                    </div>
                </div>


            </div>

            <Toast ref={toast} />
        </Layout>
    )
}


