import React, { useEffect, useState, useRef } from 'react'
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import AppAvatar from '../../avatar/Avatar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import CustomBot from '../../CustomBot';
import { ToggleButton } from 'primereact/togglebutton';
import { useParams } from 'react-router-dom';
import { chat_interface, edit_chat_interface, FUploadProfile } from '../../../../services/Settings';
import { Toast } from 'primereact/toast';
import { Chips } from 'primereact/chips';
import { Editor } from 'primereact/editor';
import { useTranslation } from 'react-i18next';

export default function ChatInterface() {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(true);


    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('chat_interface')
    }, [])

    const title = () => {
        return (
            <div className='flex justify-between'>
                <h1>{t('chat_interface')}</h1>
                <ToggleButton checked={checked} onChange={(e) => setChecked(e.value)} className="w-8rem" onLabel={t('custom')} offLabel={t('back')} />
            </div>
        )
    }

    return (
        <>
            <Card title={title}>
                {
                    checked ?
                        <Prompte /> :
                        <CustomBot />
                }
            </Card>


        </>
    )
}


export function Prompte() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const toast = useRef(null);
    const [initMessage, setInitMessage] = useState('');
    const [suggestedMessages, setSuggestedMessages] = useState([]);
    const [privacy, setPrivacy] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [profile, setProfile] = useState(null);
    const [oldProfile, setOldProfile] = useState(null);
    const [visible, setVisible] = useState(false);
    const [seconds, setSeconds] = useState('');
    const [loading, setLoading] = useState(false);

    function uploadProfile() {
        FUploadProfile(slug, { profile }).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
            setOldProfile(profile);
            setVisible(false);
        })
    }

    function save() {
        setLoading(true);
        let params = {
            initMessage,
            placeholder,
            seconds,
            suggested_messages: suggestedMessages,
            message_privacy: privacy,
        };
        edit_chat_interface(slug, params)
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to save data' });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);
        chat_interface(slug)
            .then((res) => {
                setProfile(res.data.ai.image);
                setOldProfile(res.data.ai.image);
                setInitMessage(res.data.ai.init_message);
                setPlaceholder(res.data.ai.placeholder);
                setSeconds(res.data.ai.seconds);
                setSuggestedMessages(res.data.ai.suggested_messages);
                setPrivacy(res.data.ai.message_privacy);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [slug]);




    return (
        <div className=''>
            <Toast ref={toast} />
            <div className=''>

                <div className='w-full'>
                    <label className="block text-sm font-medium mb-2">{t('initial_message')}</label>
                    <InputText value={initMessage} onChange={(e) => setInitMessage(e.target.value)} className='w-full' />
                </div>

                <div className='w-full mt-4'>
                    <label className="block text-sm font-medium mb-2">{t('suggested_messages')}</label>
                    <Chips value={suggestedMessages} onChange={(e) => setSuggestedMessages(e.value)} className='w-full' />
                </div>


                <div className='w-full mt-4'>
                    <label className="block text-sm font-medium mb-2">{t('message_placeholder')}</label>
                    <InputText value={placeholder} onChange={(e) => setPlaceholder(e.target.value)} className='w-full' />
                </div>

                <div className='w-full mt-4  prompt'>
                    <label className="block text-sm font-medium mb-2">{t('message_privacy')} </label>
                    <Editor value={privacy} onTextChange={(e) => setPrivacy(e.htmlValue)} style={{ height: '50px' }} />
                </div>


                <div className="flex w-full flex-row items-center gap-4 py-3">

                    {
                        profile ? <img src={profile} alt="Preview" className='h-14 w-14' />
                            :
                            <div className="h-14 w-14 rounded-full border border-zinc-300 bg-zinc-200"></div>
                    }
                    <div className="flex flex-col gap-1">
                        <span className="mb-1 block text-sm font-medium text-zinc-700">{t('profile_picture')}</span>
                        <div className="flex flex-row items-center gap-2">
                            <button onClick={() => setVisible(true)} className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-80 border border-zinc-200 bg-transparent shadow-sm hover:bg-zinc-100/70 hover:text-zinc-900 dark:border-zinc-800 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 disabled:bg-zinc-100/60 h-7 rounded-md px-3 text-xs" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-upload mr-2 h-4 w-4">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="17 8 12 3 7 8"></polyline>
                                    <line x1="12" x2="12" y1="3" y2="15"></line>
                                </svg>
                                {t('upload_image')}
                            </button>
                        </div>
                        <span className="mt-1 text-xs text-zinc-500">{t('supports')}</span>
                    </div>
                </div>


                <div className='w-full mt-4'>
                    <label className="block text-sm font-medium mb-2">{t('auto_show_pop_up_after')}</label>
                    <InputText keyfilter="pint" value={seconds} onChange={(e) => setSeconds(e.target.value)} className='w-full' />
                </div>


                <div className='flex justify-end items-end mt-5'>
                    <Button label={t('save')} icon="pi pi-check" onClick={save} loading={loading} />
                </div>

            </div>


            <Dialog
                header={t('profile_picture')}
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    setProfile(oldProfile);
                }}
            >
                <AppAvatar preview={profile} setPreview={setProfile} />
                <div className='flex justify-end mt-2'>
                    <Button icon="pi pi-check" label={t('save')} onClick={uploadProfile} />
                </div>
            </Dialog>
        </div>
    )
}