import React, { useRef, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

const AppAvatar = ({ preview, setPreview }) => {
    const {t} = useTranslation()
    const [src] = useState('');
    const toast = useRef(null);

    const onClose = () => {
        setPreview(null);
    };

    const onCrop = (newPreview) => {
        setPreview(newPreview);
    };

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 1048576) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: t('file_is_too_big'), life: 3000 });
            elem.target.value = '';
        }
    };

    return (
        <div className='flex justify-center' >
            <Avatar
                width={300}
                imageWidth={300}
                height={300}
                label={t('choose_a_file')}
                imageHeight={300}
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                src={src}
            />
            {/* <div>

                {preview && <img src={preview} alt="Preview" />}
            </div> */}
            <Toast ref={toast} />
        </div>
    );
};

export default AppAvatar;