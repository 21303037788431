import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTransactions, saveTransactions } from '../../services/Admin';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export default function Transactions() {
    const { t } = useTranslation()
    const toast = useRef(null);
    const dataTable = useRef();
    const [transactions, setTransactions] = useState([]);


    const columns = [
        { field: 'en', header: 'English' },
        { field: 'fr', header: 'Français' }
    ];

    function save() {
        setTimeout(() => {
            saveTransactions(transactions).then(res => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            })
        }, 1000)
    }

    useEffect(() => {
        getTransactions().then((res) => {
            setTransactions(res.data.translations)
        })
    }, [])

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
    };

    const textEditor = (options) => {
        return <InputText type="text" className='w-full' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };


    return (
        <>
            <div className="flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('transactions')}</h4>
            </div>
            <div>
                <DataTable ref={dataTable} value={transactions} editMode="cell" tableStyle={{ minWidth: '50rem' }} scrollable scrollHeight="700px">
                    {columns.map(({ field, header }) => {
                        return <Column key={field} field={field} header={header} style={{ width: '25%' }} editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete} />;
                    })}
                </DataTable>
                <div className="flex justify-end mr-4 mt-1">
                    <Button label={t('save')} onClick={save} />
                </div>
            </div>
            <Toast ref={toast} />
        </>
    )
}
