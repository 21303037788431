import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';
import { getLeads } from '../../../services/Leads';

export default function Leads() {
    const { t } = useTranslation()
    const dt = useRef(null);
    const [leads, setLeads] = useState([]);
    const [perPage, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);
    const [date, setDate] = useState('last_7_days');

    const dates = [
        { name: t('all_time'), value: 'all_time' },
        { name: t('today'), value: 'today' },
        { name: t('yesterday'), value: 'yesterday' },
        { name: t('last_7_days'), value: 'last_7_days' },
        { name: t('last_30_days'), value: 'last_30_days' },
        { name: t('this_month'), value: 'this_month' },
        { name: t('last_month'), value: 'last_month' },
        { name: t('this_year'), value: 'this_year' },
        { name: t("last_year"), value: 'last_year' },
        { name: t('last_24_hours'), value: 'last_24_hours' },
        { name: t('last_48_hours'), value: 'last_48_hours' },
        { name: t('last_7_hours'), value: 'last_7_hours' },
    ];


    const [selected, setSelected] = useState('all');
    const [filters, setFilters] = useState([
        { name: t('all_chatbots'), value: 'all' },
    ]);


    function fetchLeads(date, ai, page = 1) {
        getLeads(date, ai, page).then((res) => {
            setPerPage(res.data.per_page);
            setTotal(res.data.total);
            var data = [];
            var leads = res.data.leads.data;

            leads.forEach(lead => {
                let tab = JSON.parse(lead.data);
                let filteredTab = { ...tab };

                ['firstName', 'lastName', 'email', 'adresse', 'phone'].forEach(key => {
                    delete filteredTab[key];
                });

                data.push({
                    id: lead.id,
                    firstName: tab.firstName || '',
                    lastName: tab.lastName || '',
                    email: tab.email || '',
                    phone: tab.phone || '',
                    adresse: tab.adresse || '',
                    cp: Object.keys(filteredTab).length ? JSON.stringify(filteredTab) : '',
                    ip: lead.ip
                });
            });

            const aiFilters = res.data.ais.map(ai => ({
                name: ai.name,
                value: ai.value
            }));

            setFilters(prevFilters => {
                // Create a Set of existing filter values for quick lookup
                const existingValues = new Set(prevFilters.map(filter => filter.value));

                // Filter out any duplicates from aiFilters
                const newFilters = aiFilters.filter(aiFilter => !existingValues.has(aiFilter.value));

                // Return the updated filters
                return [
                    ...prevFilters,
                    ...newFilters,
                ];
            })

            setLeads(data)

        });
    }

    const template = (product) => {
        const cp = product.cp ? JSON.parse(product.cp) : {};
        return (
            <div>
                {
                    Object.keys(cp).map((key, index) => (
                        <p key={index}>
                            <b> {key}</b>: {cp[key]} <br />
                        </p>
                    ))
                }
            </div >
        );
    };


    const onPageChange = (event) => {
        setFirst(event.first);
        fetchLeads(date, selected, event.page + 1)
    };

    useEffect(() => {
        fetchLeads(date, selected)
    }, [date, selected])

    useEffect(() => {
        document.title = 'DialogEase - ' + t('leads')
    }, [])
    return (
        <>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('leads')}</h4>

                <div className=' space-x-2'>
                    <Dropdown value={selected} onChange={(e) => setSelected(e.value)} options={filters} optionLabel="name"
                        placeholder={t('all_chatbots')} className="w-14rem" />
                    <Dropdown value={date} onChange={(e) => setDate(e.value)} options={dates} optionLabel="name"
                        placeholder={dates[0].name} />
                </div>

            </div>

            <div className='p-card'>
                <div>
                    <DataTable ref={dt} value={leads} tableStyle={{ minWidth: '50rem' }} emptyMessage="No Leads found.">
                        <Column field="firstName" header={t('first_name')} ></Column>
                        <Column field="lastName" header={t('last_name')} ></Column>
                        <Column field="phone" header={t('phone')} ></Column>
                        <Column field="adresse" header={t('adresse')} ></Column>
                        <Column field="cp" header={t('cp')} body={template}></Column>
                        <Column field="ip" header={t('ip')}></Column>
                    </DataTable>
                    {
                        total > 0 &&
                        <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} />
                    }

                </div >
            </div>

        </>
    )
}
