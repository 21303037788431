import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element: Component, ...rest }) => {
    const isAuthenticated = useSelector(state => state.auth.user !== null);

    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;

};

export default PrivateRoute;
