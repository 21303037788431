import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'primereact/card';
import { destroyLead, editLead, fLeads } from '../../../../services/ChatAi';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup';


export default function Leads() {
    const { t } = useTranslation()
    const { slug } = useParams()
    const dt = useRef(null);
    const [leads, setLeads] = useState([]);
    const [perPage, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);
    const [date, setDate] = useState('last_7_days');
    const [lead, setLead] = useState({});
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);


    const dates = [
        { name: t('all_time'), value: 'all_time' },
        { name: t('today'), value: 'today' },
        { name: t('yesterday'), value: 'yesterday' },
        { name: t('last_7_days'), value: 'last_7_days' },
        { name: t('last_30_days'), value: 'last_30_days' },
        { name: t('this_month'), value: 'this_month' },
        { name: t('last_month'), value: 'last_month' },
        { name: t('this_year'), value: 'this_year' },
        { name: t("last_year"), value: 'last_year' },
        { name: t('last_24_hours'), value: 'last_24_hours' },
        { name: t('last_48_hours'), value: 'last_48_hours' },
        { name: t('last_7_hours'), value: 'last_7_hours' },
    ];

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };


    function fetchLeads(date, page = 1) {
        fLeads(slug, date, page).then((res) => {
            setPerPage(res.data.per_page);
            setTotal(res.data.total);
            var data = [];
            var leads = res.data.leads.data;

            leads.forEach(lead => {
                let tab = JSON.parse(lead.data);
                let filteredTab = { ...tab };

                ['firstName', 'lastName', 'email', 'adresse', 'phone'].forEach(key => {
                    delete filteredTab[key];
                });

                data.push({
                    id: lead.id,
                    firstName: tab.firstName || '',
                    lastName: tab.lastName || '',
                    email: tab.email || '',
                    phone: tab.phone || '',
                    adresse: tab.adresse || '',
                    cp: Object.keys(filteredTab).length ? JSON.stringify(filteredTab) : '',
                    ip: lead.ip
                });
            });

            setLeads(data)

        });
    }

    useEffect(() => {
        fetchLeads(date)
    }, [slug])

    const onPageChange = (event) => {
        setFirst(event.first);
        fetchLeads(date, event.page + 1)
    };


    function handleChange(event) {
        setDate(event.value)
        fetchLeads(event.value)
    }


    const template = (product) => {
        const cp = product.cp ? JSON.parse(product.cp) : {};
        return (
            <div>
                {
                    Object.keys(cp).map((key, index) => (
                        <p key={index}>
                            <b> {key}</b>: {cp[key]} <br />
                        </p>
                    ))
                }
            </div >
        );
    };






    // const cpEditor = (options) => {

    //     let cp = options.value ? JSON.parse(options.value) : {};

    //     return (
    //         <div>
    //             {
    //                 Object.keys(cp).map((key, index) => (
    //                     <div key={index}>
    //                         <label>{key}:</label>
    //                         <InputText type="text" value={cp[key]} onChange={(e) => {
    //                             let newCp = { ...cp };
    //                             newCp[key] = e.target.value;
    //                             options.editorCallback(JSON.stringify(newCp));
    //                         }} />
    //                     </div>
    //                 ))
    //             }
    //         </div>
    //     )

    // };



    function edit(data) {
        setLead(data);
        setVisible(true);
    }

    function deleteLead(id) {
        destroyLead(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setLeads(prevLeads => prevLeads.filter(l => l.id !== id));
        })
    }


    const actions = (data) => {

        return (
            <div className='flex gap-1'>
                <Button icon="pi pi-pen-to-square" severity="info" rounded aria-label="edit" onClick={() => edit(data)} />
                <Button icon="pi pi-trash" rounded severity="danger" aria-label="delete" onClick={() => deleteLead(data.id)} />
            </div>
        );
    };


    useEffect(() => {
        document.title = 'DialogEase - ' + t('activity') + ' - ' + t('leads')
    }, [])


    return (
        <>
            <Card title={t('leads')} className='min-h-[calc(100vh-107px)] notBorderB'>
                <div>
                    <div className='flex justify-between items-center mb-2'>
                        <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" className='mb-2' />
                        <Dropdown value={date} onChange={(e) => handleChange(e)} options={dates} optionLabel="name"
                            placeholder={dates[0].name} />
                    </div>
                    <DataTable ref={dt} value={leads} tableStyle={{ minWidth: '50rem' }} emptyMessage="No Leads found.">
                        <Column field="firstName" header={t('first_name')} ></Column>
                        <Column field="lastName" header={t('last_name')} ></Column>
                        <Column field="phone" header={t('phone')} ></Column>
                        <Column field="adresse" header={t('adresse')} ></Column>
                        <Column field="cp" header={t('cp')} body={template}></Column>
                        <Column field="ip" header={t('ip')}></Column>
                        <Column field="action" header="Actions" body={actions} />
                    </DataTable>
                    {
                        total > 0 &&
                        <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} />
                    }

                </div >
            </Card >

            <Dialog header={t('edit_lead')} visible={visible} modal={false} style={{ width: '30vw' }} onHide={() => { if (!visible) return; setVisible(false); }}
                breakpoints={{ '1280px': '50vw', '1024px': '70vw', '641px': '100vw' }}
            >
                <EditForm lead={lead} setLeads={setLeads} setVisible={setVisible} toast={toast} />
            </Dialog>
            <Toast ref={toast} />
        </>
    )
}

function EditForm({ lead, setLeads, setVisible, toast }) {
    const { t } = useTranslation()


    const [formData, setFormData] = useState({
        firstName: lead.firstName || '',
        lastName: lead.lastName || '',
        phone: lead.phone || '',
        adresse: lead.adresse || '',
        cp: lead.cp ? JSON.parse(lead.cp) : {},
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };
    const handleCpChange = (key, value) => {
        setFormData((prevState) => ({
            ...prevState,
            cp: {
                ...prevState.cp,
                [key]: value, // Update specific cp field
            },
        }));
    };

    function save() {
        editLead(lead.id, formData).then(res => {

            const finalFormData = {
                ...formData,
                cp: JSON.stringify(formData.cp),
            };

            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setLeads(prevLeads => prevLeads.map(l => (l.id === lead.id ? { ...l, ...finalFormData } : l)));
            setVisible(false)
        })
    }


    return (
        <div>

            <div className="mb-3">
                <label htmlFor="firstName" className="block text-900 font-medium mb-2">{t('first_name')}</label>
                <InputText id="firstName" type="text" placeholder={t('first_name')} className="w-full"
                    value={formData.firstName}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="lastName" className="block text-900 font-medium mb-2">{t('last_name')}</label>
                <InputText id="lastName" type="text" placeholder={t('last_name')} className="w-full"
                    value={formData.lastName}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="phone" className="block text-900 font-medium mb-2">{t('phone')}</label>
                <InputText id="phone" type="text" placeholder={t('phone')} className="w-full"
                    value={formData.phone}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="adresse" className="block text-900 font-medium mb-2">{t('adresse')}</label>
                <InputText id="adresse" type="text" placeholder={t('adresse')} className="w-full"
                    value={formData.adresse}
                    onChange={handleInputChange}
                />
            </div>

            {
                Object.keys(formData.cp).length > 0 &&

                <div className="mb-3">
                    <label htmlFor="cp" className="block text-900 font-medium mb-2">{t('cp')}</label>

                    {Object.entries(formData.cp).map(([key, value]) => (
                        <div key={key} className="mb-2">
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                    {key}
                                </span>
                                <InputText
                                    id={key}
                                    type="text"
                                    placeholder={t(key)}
                                    className="w-full"
                                    value={value}
                                    onChange={(e) => handleCpChange(key, e.target.value)}
                                />
                            </div>


                        </div>
                    ))}

                </div>
            }




            <div>
                <Button type="submit" label={t('save')} className="w-full" onClick={save} />
            </div>


        </div>
    )
}
