import React, { useEffect, useRef, useState } from 'react'
import Layout from './Layout'
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { createTicket, getAis } from '../../../services/Ticket';
import { Toast } from 'primereact/toast';

export default function Help() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [email, setEmail] = useState(null);
    const [chatbots, setChatbots] = useState(null);
    const [problem, setProblem] = useState("general_inquiries");
    const [severity, setSeverity] = useState("low");
    const [subject, setSubject] = useState(null);
    const [description, setDescription] = useState(null);
    const [ais, setAis] = useState([]);
    const [errors, setErrors] = useState([]);

    const problems = [
        { name: t('billing'), value: 'billing' },
        { name: t('account_management'), value: 'account_management' },
        { name: t('feature_request'), value: 'feature_request' },
        { name: t('bugs_issues'), value: 'bugs_issues' },
        { name: t('affiliate_program'), value: 'affiliate_program' },
        { name: t('partnership'), value: 'partnership' },
        { name: t('general_inquiries'), value: 'general_inquiries' },
    ];

    const severities = [
        { name: t('low'), value: 'low' },
        { name: t('normal'), value: 'normal' },
        { name: t('hight'), value: 'hight' },
        { name: t('urgent'), value: 'urgent' },
    ];



    function save() {
        setLoading(true)
        let params = {
            email,
            chatbots,
            problem,
            severity,
            subject,
            description,
        }

        createTicket(params).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
        }).catch(err => {
            if (err.response && err.response.status === 422) {
                // Handle validation errors from the server
                const validationErrors = err.response.data.errors;
                setErrors(validationErrors); // Set server-side validation errors
            }
        }).finally(() => setLoading(false))

    }





    useEffect(() => {
        document.title = 'DialogEase - ' + t('help')

        // getAis().then((res) => {
        //     setAis(res.data.ais);
        //     setEmail(res.data.email);
        // });

    }, [])
    return (
        <Layout>
            <div>
                <Card title={t('submit_a_case_to_our_customer_support_team')}>
                    <div>
                        {/* <div className="w-full">
                            <label htmlFor="email" className="block text-600 font-medium mb-2">{t('email')}</label>
                            <InputText value={email} onChange={(e) => setEmail(e.target.value)} className='w-full' disabled />
                        </div> */}

                        {/* <div className="w-full mt-3">
                            <label htmlFor="chatbots" className="block text-600 font-medium mb-2">{t('related_chatbots')}</label>
                            <MultiSelect value={chatbots} onChange={(e) => setChatbots(e.value)} options={ais} optionLabel="name" display="chip"
                                placeholder={t('select_related_chatbots')} className="w-full" invalid={errors.chatbots} />
                            {errors.chatbots && <small className="text-red-500">{errors.chatbots}</small>}
                        </div> */}

                        <div className='flex gap-3 mt-3'>
                            <div className="w-full">
                                <label htmlFor="problem" className="block text-600 font-medium mb-2">{t('selected_problem')}</label>
                                <Dropdown value={problem} onChange={(e) => setProblem(e.value)} options={problems} optionLabel="name"
                                    placeholder={t('selected_problem')} className="w-full" invalid={errors.problem} />
                                {errors.problem && <small className="text-red-500">{errors.problem}</small>}
                            </div>

                            <div className="w-full">
                                <label htmlFor="severity" className="block text-600 font-medium mb-2">{t('selected_severity')}</label>
                                <Dropdown value={severity} onChange={(e) => setSeverity(e.value)} options={severities} optionLabel="name"
                                    placeholder={t('selected_severity')} className="w-full" invalid={errors.severity} />
                                {errors.severity && <small className="text-red-500">{errors.severity}</small>}
                            </div>
                        </div>


                        <div className="w-full mt-3">
                            <label htmlFor="email" className="block text-600 font-medium mb-2">{t('subject')}</label>
                            <InputText value={subject} onChange={(e) => setSubject(e.target.value)} className='w-full' placeholder={t('subject')} invalid={errors.subject} />
                            {errors.subject && <small className="text-red-500">{errors.subject}</small>}
                        </div>

                        <div className="w-full mt-3">
                            <label htmlFor="email" className="block text-600 font-medium mb-2">{t('description')}</label>
                            <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} rows={5} className='w-full'
                                placeholder={t('please_include_all_information_relevant_to_your_issue')} invalid={errors.description} />
                            {errors.description && <small className="text-red-500">{errors.description}</small>}
                        </div>

                        <div className="w-full mt-3 flex justify-end">
                            <Button label={t('submit_ticket')} loading={loading} onClick={save} />
                        </div>

                    </div>
                </Card>
            </div>
            <Toast ref={toast} />
        </Layout>
    )
}
