import axios from 'axios';
import { BASE_URL } from "../configs/configs"

const Api = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true,
    timeout: 120000,
    withXSRFToken: true
});


Api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // List of paths where you don't want to redirect to login
            const publicPaths = [
                '/',
                '/faq',
                '/login',
                '/signup',
                '/forgot-your-password',
            ];

            // Check for dynamic routes like /password/reset/:token/:email
            const isResetPasswordPath = window.location.pathname.startsWith('/password/reset/');

            // Redirect if not on a public path or the reset password path
            if (!publicPaths.includes(window.location.pathname) && !isResetPasswordPath) {

                var backMessage = "Session expired. Please log in again.";
                sessionStorage.setItem('backMessage', backMessage)

                window.location.href = '/login';// send back messages
            }
        }
        return Promise.reject(error);
    }
);

export default Api;