import React from 'react'
import NavBar from './NavBar'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Footer from './Footer';


export default function Layout() {

    return (
        <div>
            <NavBar />
            <div className='max-w-screen-xl mx-auto mt-1 min-h-screen'>
                <Outlet />
            </div>
            <Footer/>
        </div>
    )
}
