import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest, loginSuccess, logout } from '../../../Auth/authActions';
import { useTranslation } from 'react-i18next';
import { SelectLang, VerifyEmail } from '../NavBar';
import Cookies from 'js-cookie';
import { loginUser } from '../../../services/Admin';




export default function NavBar() {
    const { t } = useTranslation()
    const user = useSelector(state => state.auth.user)
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const isExpired = useSelector(state => state.auth.isExpired)



    const navigate = useNavigate();
    const menuLeft = useRef(null);

    function capitalizeName(name) {
        return name.replace(/\b\w/g, char => char.toUpperCase());
    }

    const items = [
        {
            label: capitalizeName(user.name),
            items: [
                {
                    label: t('dashboard'),
                    icon: 'pi pi-home',
                    command: () => {
                        navigate('/dashboard');
                    }
                },
                {
                    label: t('account_settings'),
                    icon: 'pi pi-cog',
                    command: () => {
                        navigate('/dashboard/settings');
                    }
                },
                {
                    label: t('sign_out'),
                    icon: 'pi pi-sign-out',
                    command: () => {
                        log_out();
                    }
                },

            ]
        },
    ];

    if (user.admin == 1 || Cookies.get('KQzY4sGwrVgt2GjDyhp6s')) {
        items[0].items.unshift(
            {
                label: t('Admin'),
                icon: 'pi pi-warehouse',
                command: () => {
                    if (Cookies.get('KQzY4sGwrVgt2GjDyhp6s')) {
                        const email_admin = Cookies.get('KQzY4sGwrVgt2GjDyhp6s');
                        // dispatch(logout());

                        dispatch(loginRequest());

                        loginUser(email_admin).then((res) => {
                            let data = res.data;
                            dispatch(loginSuccess(data));
                            Cookies.remove('KQzY4sGwrVgt2GjDyhp6s');
                            navigate('/admin');
                        })
                    } else {
                        navigate('/admin');
                    }
                }

            })
    } else {
        items[0].items.splice(2, 0, // Insert at index 2
            {
                label: t('tickets'),
                icon: 'pi pi-ticket',
                command: () => {
                    navigate('/dashboard/tickets');
                }
            }
        );
    }


    function log_out() {
        dispatch(logout(navigate));
    }



    return (
        <div>
            <nav className="bg-white w-full border-b border-gray-200 ">
                <div className="flex flex-wrap justify-center items-center gap-1 min-[640px]:justify-between p-2 xl:mx-6">
                    <img
                        src="/images/logo.png"
                        className="h-4rem cursor-pointer"
                        alt="Logo"
                        onClick={() => navigate("/dashboard")}
                    />

                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        <SelectLang />

                        <Avatar
                            icon="pi pi-user"
                            style={{
                                height: "45px",
                                width: "45px",
                                padding: "13px",
                            }}
                            shape="circle"
                            onClick={(event) => menuLeft.current.toggle(event)}
                            aria-controls="popup_menu_left"
                        />
                        <Menu
                            model={items}
                            popup
                            ref={menuLeft}
                            id="popup_menu_left"
                        />
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none"
                            aria-controls="navbar-sticky"
                            aria-expanded={isOpen}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 17 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M1 1h15M1 7h15M1 13h15"
                                />
                            </svg>
                        </button>
                    </div>
                    <div
                        id="navbar-sticky"
                        className={` items-center justify-between  md:w-auto hidden w-full md:flex md:order-1 ${isOpen ? "block" : "hidden"
                            }`}
                    >
                        <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">




                            <li>
                                <NavLink
                                    to={
                                        "/dashboard/chatbot/" +
                                        slug +
                                        "/playground"
                                    }
                                    className={({ isActive }) =>
                                        `${isActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        } block py-2 px-3  rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("playground")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to={
                                        "/dashboard/chatbot/" +
                                        slug +
                                        "/activity"
                                    }
                                    className={({ isActive }) =>
                                        `${isActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        } block py-2 px-3  rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("activity")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to={
                                        "/dashboard/chatbot/" +
                                        slug +
                                        "/sources"
                                    }
                                    className={({ isActive }) =>
                                        `${isActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        } block py-2 px-3  rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("sources")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to={
                                        "/dashboard/chatbot/" +
                                        slug +
                                        "/connect"
                                    }
                                    className={({ isActive }) =>
                                        `${isActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        } block py-2 px-3  rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("connect")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to={
                                        "/dashboard/chatbot/" +
                                        slug +
                                        "/settings"
                                    }
                                    className={({ isActive }) =>
                                        `${isActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        } block py-2 px-3  rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("settings")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to={
                                        "/dashboard/chatbot/" +
                                        slug +
                                        "/statistics"
                                    }
                                    className={({ isActive }) =>
                                        `${isActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        } block py-2 px-3  rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("statistic")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {
                (isExpired.verified == false) &&
                <VerifyEmail />
            }

        </div>
    );
}
