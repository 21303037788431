import React, { useState } from "react";
import { Button } from "primereact/button";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Footer from "./dashboard/Footer";
export default function Home() {



    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
    };

    return (
        <>
            <div className="min-h-screen">
                <NavHome />

                <div className="grid grid-nogutter  text-800 mt-6 max-w-screen-xl mx-auto h-30rem">
                    <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center">
                        <section>
                            <span className="block text-6xl font-bold mb-1">
                                Custom ChatGPT for your business
                            </span>
                            <p className="mb-4 mt-2 text-700 line-height-3">
                                Build a custom GPT, embed it on your website and let
                                it handle customer support, lead generation, engage
                                with your users, and more.
                            </p>
                            <div className="flex gap-3">
                                <Button
                                    label="Build your Chatbot"
                                    icon="pi pi-arrow-right"
                                    iconPos="right"
                                    className="p-button-raised"
                                    onClick={() => changeLanguage("en")}
                                />
                                <Button
                                    label="Watch Video"
                                    icon="pi pi-play-circle"
                                    className="p-button-outlined"
                                />
                            </div>
                        </section>
                    </div>
                    <div className="col-12 md:col-6 overflow-hidden flex items-center">
                        <img
                            src="/images/laptop.png"
                            alt="hero-1"
                            className="md:ml-auto block "
                            style={{
                                clipPath:
                                    "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)",
                            }}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}


export function NavHome() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isAuth = useSelector(state => state.auth.user !== null)
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
                <img
                    src="/images/logo.png"
                    className="h-10 cursor-pointer"
                    alt="Logo"
                />

                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                    {isAuth ? (
                        <Button
                            label="Dashboard"
                            size="small"
                            icon="pi pi-arrow-right"
                            iconPos="right"
                            onClick={() => navigate("/dashboard")}
                        />
                    ) : (
                        <Button
                            label="Try for Free"
                            size="small"
                            icon="pi pi-arrow-right"
                            iconPos="right"
                            onClick={() => navigate("/login")}
                        />
                    )}
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        className="inline-flex items-center p-2 !w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none"
                        aria-controls="navbar-sticky"
                        aria-expanded={isOpen}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className={`items-center justify-between    md:w-auto  hidden w-full md:flex md:order-1 ${isOpen ? "block" : "hidden"
                        }`}
                    id="navbar-sticky"

                // className="items-center justify-between hidden w-full
                // md:flex md:w-auto md:order-1" id="navbar-sticky"
                >
                    <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        <li>
                            <a
                                href="#"
                                className="block py-2 px-3 text-gray-900 rounded   hover:text-[var(--primary-color)] md:p-0"
                                aria-current="page"
                            >
                                Affiliates
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="block py-2 px-3 text-gray-900 rounded   hover:text-[var(--primary-color)] md:p-0"
                            >
                                Pricing
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
