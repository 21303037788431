import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card'
import { useTranslation } from 'react-i18next'
import { InputSwitch } from 'primereact/inputswitch';
import { NavLink, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { destroyWebhook, FUpdateStatus, getWebhooks, storeWebhook, testWebhook, updateWebhook } from '../../../../services/Webhook';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { deleteCustomAction, getActions, storeCustomAction, updateCustomAction } from '../../../../services/CustomAction';
import { Checkbox } from 'primereact/checkbox';

export default function Actions() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]);
    const [url, setUrl] = useState('');
    const [token, setToken] = useState(null);
    const [body, setBody] = useState([]);
    const [id, setID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [errors, setErrors] = useState({ name: null, type: null, url: null });
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        fetchAction();
    }, [slug])


    function save() {


        const isValid = body.every(b => b.name.trim() !== '' && b.description.trim() !== '');

        if (!isValid) {
            const newErrors = {};
            newErrors.body = "Please enter all fields";
            setErrors(newErrors);
            return;
        }

        if (isEdit) {
            update();
            return;
        }

        setLoading(true);
        let params = {
            name,
            url,
            token,
            description,
            body,
            type: type ? 'POST' : 'GET',
            slug_ai: slug,
        }

        storeCustomAction(params)
            .then((res) => {
                fetchAction();
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
                close();
            })
            .catch((err) => {
                const newErrors = {};
                if (err.response && err.response.status === 422) {
                    if (err.response.data.errors && err.response.data.errors.name) {
                        newErrors.name = err.response.data.errors.name[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.type) {
                        newErrors.type = err.response.data.errors.type[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.url) {
                        newErrors.url = err.response.data.errors.url[0];
                    }

                    if (err.response.data.errors && err.response.data.errors.body) {
                        newErrors.body = err.response.data.errors.body[0];
                    }

                    if (err.response.data.errors && err.response.data.errors.description) {
                        newErrors.description = err.response.data.errors.description[0];
                    }

                    setErrors(newErrors);
                } else if (err.response && err.response.status === 403) {
                    toast.current.show({
                        severity: 'warn',
                        summary: "Warning",
                        detail: err.response.data.message,
                        life: 8000,
                    });
                } else {
                    newErrors.body = "Action body non valid";
                    setErrors(newErrors);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function update() {
        setLoading(true);
        let params = {
            name,
            description,
            url,
            token,
            body,
            type: type ? 'POST' : 'GET',
        }

        updateCustomAction(id, params)
            .then((res) => {
                fetchAction();
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
                close();
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    const newErrors = {};
                    if (err.response.data.errors && err.response.data.errors.name) {
                        newErrors.name = err.response.data.errors.name[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.type) {
                        newErrors.type = err.response.data.errors.type[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.url) {
                        newErrors.url = err.response.data.errors.url[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.body) {
                        newErrors.body = err.response.data.errors.body[0];
                    }
                    if (err.response.data.errors && err.response.data.errors.description) {
                        newErrors.description = err.response.data.errors.description[0];
                    }

                    setErrors(newErrors);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }


    function fetchAction() {
        getActions(slug).then((response) => {
            setData(response.data.actions);
            setDisabled(response.data.disabled);
        });
    }


    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('actions')
    }, [])


    const title = () => {
        return (
            <div className='flex justify-between'>
                <h1>{t('actions')}</h1>
                <Button label={t('add_action')} disabled={disabled} onClick={() => setVisible(true)} />
            </div>
        )
    }


    function edit(data) {
        setID(data.id);
        setIsEdit(true)
        setVisible(true);
        setName(data.name);
        setType(data.type === 'POST');
        setUrl(data.url);
        setToken(data.token);
        setBody(JSON.parse(data.body) ?? []);
        setDescription(data.description);

    }

    function deleteAction(id) {
        deleteCustomAction(id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            fetchAction();
        })
    }

    function close() {
        setID(null);
        setVisible(false);
        setIsEdit(false);
        setName('');
        setType(false);
        setUrl('');
        setToken(null);
        setBody([]);
        setDescription('');
        setErrors({ name: null, type: null, url: null });
    }



    const handleAddBody = () => {
        setBody([...body, { name: '', description: '', required: false }]);
    };

    const handleRemoveBody = (index) => {
        const newBody = body.filter((_, i) => i !== index);
        setBody(newBody);
    };

    const handleChange = (index, field, value) => {
        const newBody = [...body];
        newBody[index][field] = value;
        setBody(newBody);
    };


    const actions = (data) => {
        return (
            <div className='flex gap-1'>
                <Button icon="pi pi-pen-to-square" severity="info" rounded aria-label="edit" onClick={() => edit(data)} />
                <Button icon="pi pi-trash" rounded severity="danger" aria-label="delete" onClick={() => deleteAction(data.id)} />
            </div>
        );
    };


    function changeName(value) {

        let newName = value
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/[^\w\-]+/g, '')
            .replace(/[0-9]+/g, '')
            .replace(/__+/g, '_');

        setName(newName);
    }


    return (
        <>
            <Card title={title} className='min-h-[700px]'>
                <DataTable value={data} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="name" header={t('name_webhook')}></Column>
                    <Column field="type" header={t('sending_type')}></Column>
                    <Column field="url" header={t('url')}></Column>
                    <Column field="" header={t('actions')} body={actions}></Column>
                </DataTable>
            </Card>

            <Dialog header={t('add_action')} visible={visible} onHide={() => { if (!visible) return; close(); }}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <form>
                    <div className='flex gap-4'>
                        <div className='w-full mt-4'>
                            <label className="block text-sm font-medium mb-2">{t('name_webhook')}</label>
                            <InputText value={name} onChange={(e) => changeName(e.target.value)} className='w-full' invalid={errors.name} />
                            {errors.name && (
                                <small className="text-red-500">{errors.name}</small>
                            )}
                        </div>

                        <div className='w-full mt-4'>
                            <label className="block text-sm font-medium mb-2">{t('sending_type')}</label>
                            <div className='flex items-center ltr:justify-start rtl:justify-end gap-4 h-12  dir-ltr'>
                                <span onClick={() => setType(false)} className={`select-none cursor-pointer ${!type && 'text-primary  font-bold'}`}>GET</span>
                                <InputSwitch className='sending_type' checked={type} onChange={(e) => setType(e.value)} />
                                <span onClick={() => setType(true)} className={`select-none cursor-pointer ${type && 'text-primary  font-bold'}`}>POST</span>
                            </div>
                        </div>
                    </div>

                    <div className='w-full mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('description')}</label>
                        <InputTextarea value={description} rows={5} onChange={(e) => setDescription(e.target.value)} className='w-full' invalid={errors.description} placeholder={t('description')} />
                        {errors.description && (
                            <small className="text-red-500">{errors.description} <br /></small>
                        )}
                    </div>


                    <div className='w-full mt-4'>
                        <div className='gap-2 flex justify-between items-center' >
                            <label className="block text-sm font-medium mb-2">{t('action_body')}</label>
                            <Button label={t('add_param')} size='small' type='button' onClick={handleAddBody} />
                        </div>

                        {body.map((b, index) => (
                            <div key={index} className="mt-3 qna-card p-2">

                                <div className="w-full grid gap-1">
                                    <div className="col-3">
                                        <label htmlFor={"name" + index} className="block text-900 font-medium">
                                            {t('name')}
                                        </label>
                                        <InputText value={b.name} id={'name' + index} onChange={(e) => handleChange(index, 'name', e.target.value)} placeholder={t('name')} className='w-full' />
                                    </div>
                                    <div className="col">
                                        <label htmlFor={"description" + index} className="block text-900 font-medium">
                                            {t('description')}
                                        </label>
                                        <InputText value={b.description} id={'description' + index} placeholder={t('description')} onChange={(e) => handleChange(index, 'description', e.target.value)} className='w-full' />
                                    </div>
                                    <div className="col-1">
                                        <label htmlFor={"required" + index} className="block text-900 font-medium">
                                            {t('required')}
                                        </label>
                                        <div className='flex justify-center items-center h-[50px]'>
                                            <Checkbox inputId={"required" + index} onChange={(e) => handleChange(index, 'required', e.checked)} checked={b.required}></Checkbox>
                                        </div>
                                    </div>
                                    <div className="mt-[30px]">
                                        <Button icon="pi pi-trash" type='button' onClick={() => handleRemoveBody(index)} severity="danger" />
                                    </div>
                                </div>
                            </div>
                        ))}

                        {errors.body && (
                            <small className="text-red-500">{errors.body}</small>
                        )}

                    </div>

                    <div className='w-full mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('token')}</label>
                        <InputText value={token} onChange={(e) => setToken(e.target.value)} className='w-full' />

                    </div>

                    <div className='w-full mt-4'>
                        <label className="block text-sm font-medium mb-2">{t('url_webhook')}</label>
                        <InputText value={url} onChange={(e) => setUrl(e.target.value)} className='w-full' invalid={errors.url} />
                        {errors.url && (
                            <small className="text-red-500">{errors.url}</small>
                        )}
                    </div>

                    <div className='flex justify-end mt-4'>
                        <Button label={t('save')} className='min-w-32' icon="pi pi-check" type='button' onClick={save} loading={loading} />
                    </div>

                </form>
            </Dialog>

            <Toast ref={toast} />
        </>
    )
}
