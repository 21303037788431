// RoutesFile.js
import React from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "../Auth/login.jsx";
import Dashboard from "../pages/dashboard/index.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
import DashboardLayout from "../pages/dashboard/Layout.jsx";
import CreateNewChatbot from "../pages/dashboard/CreateNewChatbot.jsx";
import ForgotYourPassword from "../Auth/ForgotYourPassword.jsx";
import ResetPassword from "../Auth/ResetPassword.jsx";
import IndexBot from "../pages/dashboard/ChatBot/IndexBot.jsx"
import ChatBotLayout from "../pages/dashboard/ChatBot/Layout.jsx"
import ChatLogs from "../pages/dashboard/ChatBot/Activity/ChatLogs.jsx";
import ActivitySideBar from "../pages/dashboard/ChatBot/Activity/SideBar.jsx";
import Leads from "../pages/dashboard/ChatBot/Activity/Leads.jsx";
import Sources from "../pages/dashboard/ChatBot/Sources.jsx";
import General from "../pages/dashboard/ChatBot/Settings/General.jsx";
import SettingsSideBar from "../pages/dashboard/ChatBot/Settings/SideBar.jsx";
import SettingsDashboardSideBar from "../pages/dashboard/Settings/SideBar.jsx";
import Ai from "../pages/dashboard/ChatBot/Settings/Ai.jsx";
import ChatInterface from "../pages/dashboard/ChatBot/Settings/ChatInterface.jsx";
import SettingsLeads from "../pages/dashboard/ChatBot/Settings/Leads.jsx";
import AdminLayout from "../pages/admin/Layout.jsx";
import Users from "../pages/admin/Users.jsx";
import PrivateRouteAdmin from "./PrivateRouteAdmin.jsx";
import NotFound from "../pages/404.jsx";
import Transactions from "../pages/admin/Transactions.jsx";
import AppointmentIndex from "../pages/dashboard/Appointment/Index.jsx";
import DLeads from "../pages/dashboard/Leads/Leads.jsx";
import FAQ from "../pages/FAQ.jsx";
import Statistical from "../pages/dashboard/ChatBot/Statistical.jsx";
import Training from "../pages/dashboard/ChatBot/Settings/Training.jsx";
import Appointment from "../pages/dashboard/ChatBot/Settings/Appointment.jsx";
import Faq from "../pages/admin/Faq.jsx";
import TicketsAdmin from "../pages/admin/Tickets/Tickets.jsx";
import Usage from "../pages/dashboard/Usage/Usage.jsx";
import Help from "../pages/dashboard/Help/Help.jsx";
import Tickets from "../pages/dashboard/Help/Tickets.jsx";
import Chat from "../pages/dashboard/Help/Chat.jsx";
import TicketsChat from "../pages/admin/Tickets/TicketsChat.jsx";
import PrivateRouteClient from "./PrivateRouteClient.jsx";
import Webhooks from "../pages/dashboard/ChatBot/Settings/Webhooks.jsx";
import ConnectSideBar from "../pages/dashboard/ChatBot/Connect/SideBar.jsx";
import Embed from "../pages/dashboard/ChatBot/Connect/Embed.jsx";
import Integrations from "../pages/dashboard/ChatBot/Connect/Integrations.jsx";
import DocsIndex from "../pages/dashboard/Docs/DocsIndex.jsx";
import SideBar from "../pages/dashboard/Docs/SideBar.jsx";
import GeneralSettings from "../pages/dashboard/Settings/General.jsx";
import CreateChatbot from "../pages/dashboard/Docs/CreateChatbot.jsx";
import Home from "../pages/Home.jsx";
import Plans from "../pages/dashboard/Settings/Plans.jsx";
import Billing from "../pages/dashboard/Settings/Billing.jsx";
import Signup from "../Auth/signup.jsx";
import PublicRoute from "./PublicRoute.jsx";
import Actions from "../pages/dashboard/ChatBot/Settings/Actions.jsx";


export default function RoutesFile() {
    return (
        <div className="bg-[#f4f5f9]">
            <BrowserRouter>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/app/test" element={<Home />} />
                    <Route path="/" element={<Navigate to="dashboard" replace />} />

                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/:lang/faq" element={<FAQ />} />

                    <Route path="/login" element={<Navigate to="/en/login" replace />} />
                    <Route path="/:lang/login" element={<PublicRoute element={Login} />} />

                    <Route path="/signup" element={<Navigate to="/en/signup" replace />} />
                    <Route path="/:lang/signup" element={<PublicRoute element={Signup} />} />

                    <Route path="/forgot-your-password" element={<Navigate to="/en/forgot-your-password" replace />} />
                    <Route path="/:lang/forgot-your-password" element={<PublicRoute element={ForgotYourPassword} />} />

                    <Route path="/password/reset/:token/:email" element={<ResetPassword />} />
                    <Route path="/:lang/password/reset/:token/:email" element={<ResetPassword />} />

                    {/* Private Routes */}
                    <Route path="/dashboard" element={<PrivateRoute element={DashboardLayout} />}>
                        <Route index element={<Navigate to="/dashboard/chatbots" replace />} />

                        <Route path="chatbots">
                            <Route index element={<PrivateRoute element={Dashboard} />} />
                            <Route path="create-new-chatbot" element={<PrivateRoute element={CreateNewChatbot} />} />
                            {/* <Route path="custombot/:slug" element={<PrivateRoute element={CustomBot} />} />
                            <Route path="editbot/:slug" element={<PrivateRoute element={CreateNewChatbot} />} />
                            <Route path="botTools/:slug" element={<PrivateRoute element={BotTools} />} /> */}
                        </Route>

                        {/* <Route path="settings" element={<PrivateRoute element={Setting} />} /> */}


                        <Route path="settings" element={<PrivateRoute element={SettingsDashboardSideBar} />}>
                            <Route index element={<Navigate to="general" replace />} />
                            <Route path="general" element={<PrivateRoute element={GeneralSettings} />} />
                            <Route path="plans" element={<PrivateRoute element={Plans} />} />
                            <Route path="billing" element={<PrivateRoute element={Billing} />} />
                        </Route>


                        <Route path="appointment" element={<PrivateRoute element={AppointmentIndex} />} />
                        <Route path="usage" element={<PrivateRoute element={Usage} />} />
                        <Route path="leads" element={<PrivateRoute element={DLeads} />} />

                        <Route path="docs" element={<PrivateRoute element={SideBar} />}>
                            <Route index element={<Navigate to="getting-started" replace />} />
                            <Route path="getting-started" element={<PrivateRoute element={DocsIndex} />} />
                            <Route path="create-a-chatbot" element={<PrivateRoute element={CreateChatbot} />} />
                        </Route>

                    </Route>

                    <Route path="/dashboard/help" element={<PrivateRouteClient element={Help} />} />
                    <Route path="/dashboard/tickets" element={<PrivateRouteClient element={Tickets} />} />
                    <Route path="/dashboard/tickets/:id" element={<PrivateRouteClient element={Chat} />} />

                    {/* ChatBot Layout */}
                    <Route path="/dashboard/chatbot/:slug" element={<PrivateRoute element={ChatBotLayout} />}>
                        <Route index element={<Navigate to="playground" replace />} />

                        <Route path="playground" element={<PrivateRoute element={IndexBot} />} />

                        <Route path="connect" element={<PrivateRoute element={ConnectSideBar} />}>
                            <Route index element={<Navigate to="embed" replace />} />
                            <Route path="embed" element={<PrivateRoute element={Embed} />} />
                            <Route path="integrations" element={<PrivateRoute element={Integrations} />} />
                        </Route>

                        <Route path="sources" element={<PrivateRoute element={Sources} />} />

                        <Route path="statistics" element={<PrivateRoute element={Statistical} />} />

                        <Route path="activity" element={<PrivateRoute element={ActivitySideBar} />}>
                            <Route index element={<Navigate to="chat-logs" replace />} />
                            <Route path="chat-logs" element={<PrivateRoute element={ChatLogs} />} />
                            <Route path="leads" element={<PrivateRoute element={Leads} />} />
                        </Route>

                        <Route path="settings" element={<PrivateRoute element={SettingsSideBar} />}>
                            <Route index element={<Navigate to="general" replace />} />
                            <Route path="general" element={<PrivateRoute element={General} />} />
                            <Route path="ai" element={<PrivateRoute element={Ai} />} />
                            <Route path="chat-interface" element={<PrivateRoute element={ChatInterface} />} />
                            <Route path="leads" element={<PrivateRoute element={SettingsLeads} />} />
                            <Route path="training" element={<PrivateRoute element={Training} />} />
                            <Route path="appointment" element={<PrivateRoute element={Appointment} />} />
                            <Route path="webhooks" element={<PrivateRoute element={Webhooks} />} />
                            <Route path="actions" element={<PrivateRoute element={Actions} />} />
                        </Route>

                    </Route>


                    {/* Admin Layout */}
                    <Route path="/admin" element={<PrivateRouteAdmin element={AdminLayout} />}>
                        <Route index element={<Navigate to="users" replace />} />
                        <Route path="users" element={<PrivateRouteAdmin element={Users} />} />
                        <Route path="translation" element={<PrivateRouteAdmin element={Transactions} />} />
                        <Route path="faq" element={<PrivateRouteAdmin element={Faq} />} />
                        <Route path="tickets" element={<PrivateRouteAdmin element={TicketsAdmin} />} />
                        <Route path="tickets/:id" element={<PrivateRouteAdmin element={TicketsChat} />} />
                    </Route>


                    <Route path="*" element={<NotFound />} />

                </Routes>
            </BrowserRouter>
        </div >
    );
}

