import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { apiAI, editInstructions, FModules } from '../../../../services/Settings';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';

export default function Ai() {
    const { t } = useTranslation()
    const { slug } = useParams();
    const [instructions, setInstructions] = useState('');
    const [old, setOld] = useState('');
    const [loading, setLoading] = useState(false)
    const toast = useRef(null);
    const [modules, setModules] = useState(null);
    const [model, setModel] = useState(null);
    const [models, setModels] = useState([]);
    const [selected, setSelected] = useState(null);


    function save() {
        setLoading(true)
        editInstructions(slug, { instructions, model }).then((response) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
            setLoading(false)
            setOld(instructions);
        })
    }


    function handleChange(event) {
        setSelected(event.value)
        if (event.value.id !== 1) {
            setInstructions(event.value.instruction)
        } else {
            setInstructions(old)
        }
    }

    function Reset() {
        setInstructions(old);
        setSelected(null);
    }

    useEffect(() => {
        apiAI(slug).then((res) => {
            setInstructions(res.data.ai.instructions);
            setOld(res.data.ai.instructions);
            setModels(res.data.models);
            setModel(res.data.ai.model)
        })

        FModules().then((res) => {
            setModules(res.data.modules);
            if (res.data.modules.length) {
                setSelected(res.data.modules[0])
            }
        })

    }, [slug])

    useEffect(() => {
        document.title = 'DialogEase - ' + t('settings') + ' - ' + t('ai')
    }, [])

    return (
        <>
            <Toast ref={toast} />
            <Card title="AI">
                <div className="w-full">
                    <label htmlFor="model" className="block text-600 font-medium mb-2">{t('model')}</label>
                    <Dropdown value={model} onChange={(e) => setModel(e.value)} options={models} optionLabel="name"
                        placeholder={t('select_a_model')} className="w-full" />

                </div>


                <div className="w-full mt-6">
                    <div className='flex justify-between items-center mb-2'>
                        <label htmlFor="instructions" className="block text-600 font-medium">{t('instructions')}</label>
                        <div className="flex justify-content-center  gap-2">
                            <Dropdown value={selected} onChange={(e) => handleChange(e)} options={modules} optionLabel="name"
                            />
                            <Button label={t('reset')} onClick={Reset} severity="secondary" />
                        </div>
                    </div>
                    <InputTextarea value={instructions} id='instructions' onChange={(e) => setInstructions(e.target.value)} rows={20} className='w-full' />
                </div>

                <p className='mt-2'>
                    {t('the_instructions_allow_you_to_customize_your_chatbot')}
                </p>

                <div className='mt-2 flex justify-end'>
                    <Button label={t('save')}icon="pi pi-check" onClick={save} loading={loading} />
                </div>
            </Card>
        </>
    )
}
