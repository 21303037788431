import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "./authActions";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Toast } from 'primereact/toast';
import i18n from 'i18next';
import { login } from "./authActions";


const Signup = () => {
    const { t } = useTranslation();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [errors, setErrors] = useState({});
    const { lang } = useParams();
    const [next, setNext] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        i18n.changeLanguage(lang || 'en');
    }, [lang])



    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const nextParam = searchParams.get("next");
        if (nextParam) {
            setNext(nextParam);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = { name, email, password, password_confirmation: cPassword };

        signup(payload).then((response) => {
            // navigate('/admin/users', { state: { message: response.data.message } });
            toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });

            setTimeout(() => {
                dispatch(login({ email, password }, navigate, next));
            }, 3000)

        }).catch((err) => {
            if (err.response && err.response.status === 422) {
                const apiErrors = err.response.data.errors;
                const newErrors = {};

                for (const field in apiErrors) {
                    if (apiErrors.hasOwnProperty(field)) {
                        newErrors[field] = apiErrors[field].join(", ");
                    }
                }
                setErrors(newErrors);
            }
        })
    };

    useEffect(() => {
        document.title = 'DialogEase - ' + t('sign_up')
    }, [])

    return (

        <div className="flex align-items-center justify-content-center min-h-screen">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-5">
                <div className="flex flex-col items-center mb-5">
                    <img src="/images/logo.png" alt="hyper" className="h-auto w-auto" />
                    <div className="text-xl mb-3">{t('create_account_free')}</div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="block text-900 font-medium mb-2">{t('name')}</label>
                        <InputText id="name" type="text" placeholder={t('name')} className="w-full"
                            invalid={errors.name}
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                        {errors.name && (
                            <small className="text-red-500">{errors.name}</small>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email" className="block text-900 font-medium mb-2">{t('email')}</label>
                        <InputText id="email" type="text" placeholder={t('email')} className="w-full"
                            invalid={errors.email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        {errors.email && (
                            <small className="text-red-500">{errors.email}</small>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="block text-900 font-medium mb-2">{t('password')}</label>
                        <InputText id="password" type="password" placeholder={t('password')} className="w-full"
                            value={password}
                            invalid={errors.password}
                            onChange={(e) => setPassword(e.target.value)} />
                        {errors.password && (
                            <small className="text-red-500">{errors.password}</small>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="c_password" className="block text-900 font-medium mb-2"> {t('confirm_password')}</label>
                        <InputText id="c_password" type="password" placeholder={t('confirm_password')} className="w-full"
                            value={cPassword}
                            onChange={(e) => setCPassword(e.target.value)} />
                    </div>

                    <Button label={t('sign_up')} className="w-full my-5" />

                    <div className="flex align-items-center  justify-center ">
                        <span>{t('you_have_an_account')}</span>  <NavLink to={`/${lang || 'en'}/login${next ? '?next=' + next : ''}`} className="font-medium no-underline ml-2 text-primary text-right cursor-pointer">{t('sign_in')}</NavLink>
                    </div>

                </form>
            </div>
            <Toast ref={toast} />
        </div>


    );
};

export default Signup;
