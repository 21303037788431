import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const getLeads = async (date, ai, page) => {
    await Csrf.getCookie();
    return await Api.get(`/getLeads`, {
        params: {
            page,
            ...{ date, ai }
        }
    });
}