import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { getStatistic } from '../../../services/Statistical';
import { NavLink, useParams } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';

export default function Statistical() {
    const { slug } = useParams()
    const { t } = useTranslation()
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        fetchStatistics();
    }, [slug])


    function fetchStatistics(limit = 10, page = 1) {

        getStatistic(slug, limit, page).then(res => {
            setUrls(res.data.urls)
            setPerPage(res.data.per_page);
            setTotal(res.data.total);
        })
    }


    const urlBody = (rowData) => {
        return <>
            <NavLink to={rowData.url} target='_blank' className="font-medium no-underline ml-2 hover:text-primary cursor-pointer">{rowData.url}</NavLink>
            <span className='ml-2 font-medium'>({rowData.count})</span>
        </>
    }

    const title = () => {
        return <>
            <span className='text-xl'>
                {t('customers_mostly_start_chats_from_these_pages')}
            </span>
        </>
    }


    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">{t('urls')}</span>
        </div>
    );

    const onPageChange = (event) => {
        setFirst(event.first);
        setPerPage(event.rows);
        fetchStatistics(event.rows, event.page + 1)
    };

    useEffect(() => {
        document.title = 'DialogEase - ' + t('statistic')
    }, [])
    return (
        <>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('statistic')}</h4>
            </div>

            <Card title={title} subTitle={t('from_all_your_conversations')}>
                <DataTable value={urls} tableStyle={{ minWidth: '50rem' }} emptyMessage={t('no_links_found')}  >
                    <Column field="url" header={t('urls')} body={urlBody}></Column>
                </DataTable>
                {
                    total > 0 &&
                    <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 50, 100]} />
                }
            </Card >

        </>
    )
}
