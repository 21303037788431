import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

export default function SideBar() {
    const { t } = useTranslation()
    return (
        <>
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('settings')}</h4>
            </div>

            <div className='md:flex gap-5 block'>
                <div className='md:w-20rem w-full p-card p-2 '>
                    <ul className="gap-2  flex justify-center md:block">
                        <li>
                            <NavLink to="general" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-cog"></i>
                                {t('general')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="ai" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-sparkles"></i>
                                {t('ai')}
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="chat-interface" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-palette"></i>
                                {t('chat_interface')}
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="leads" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-users"></i>
                                {t('leads')}
                            </NavLink>
                        </li>

                        <li className='hidden'>
                            <NavLink to="training" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-microchip-ai"></i>
                                {t('training')}
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="appointment" className={({ isActive }) => `${isActive ? 'text-primary bg-zinc-50 ' : 'text-gray-900'}   group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`}>
                                <i className="pi pi-thumbtack"></i>
                                {t('appointment')}
                            </NavLink>
                        </li>

                    </ul>
                </div>
                <div className='w-full'>
                    <Outlet />
                </div>
            </div>
        </>
    )
}
