import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { getStatistic } from '../../../services/Statistical';
import { NavLink, useParams } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import moment from 'moment-timezone';
import { Dropdown } from 'primereact/dropdown';

export default function Statistical() {
    const { slug } = useParams()
    const { t } = useTranslation()
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);
    const [urls, setUrls] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [leads, setLeads] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [leadConversionRate, setLeadConversionRate] = useState([]);
    const [appointmentConversionRate, setAppointmentConversionRate] = useState([]);
    const [chartData, setChartData] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [chartDataLead, setChartDataLead] = useState({});
    const [chartDataAppointments, setChartDataAppointments] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [dates, setDates] = useState([moment().subtract(7, 'days').toDate(), moment().subtract(1, 'days').toDate()]);
    const [datesSend, setDatesSend] = useState([moment().subtract(7, 'days').toDate(), moment().subtract(1, 'days').toDate()]);
    const [date, setDate] = useState('last_7_days');

    const datesOptions = [
        { name: t('today'), value: 'today' },
        { name: t('yesterday'), value: 'yesterday' },
        { name: t('last_7_days'), value: 'last_7_days' },
        { name: t('last_30_days'), value: 'last_30_days' },
        { name: t('this_month'), value: 'this_month' },
        { name: t('last_month'), value: 'last_month' },
        { name: t('this_year'), value: 'this_year' },
        { name: t("last_year"), value: 'last_year' },
    ];

    useEffect(() => {
        fetchStatistics();
    }, [slug, dates])

    async function fetchStatistics(limit = 10, page = 1) {
        try {
            const res = await getStatistic(slug, limit, page, datesSend);

            setUrls(res.data.urls);
            setTotal(res.data.total);
            chart(res.data.data, res.data.utm_conversations, res.data.utm_leads, res.data.utm_appointments);
            setConversations(res.data.conversations);
            setLeads(res.data.leads);
            setAppointments(res.data.appointments);
            setLeadConversionRate(res.data.lead_conversion_rate);
            setAppointmentConversionRate(res.data.appointment_conversion_rate);
        } catch (error) {
        }
    }


    function chart(stats, utm, leads, appointments) {
        const documentStyle = getComputedStyle(document.documentElement);

        const getColor = (key, alpha = '') =>
            documentStyle.getPropertyValue(key) + alpha;

        const textColor = getColor('--text-color');
        const textColorSecondary = getColor('--text-color-secondary');
        const surfaceBorder = getColor('--surface-border');

        const createDataset = (dataArray, colors) =>
            dataArray.map((item, index) => ({
                label: item.source,
                data: item.count,
                borderColor: colors[index],
                fill: false,
                tension: 0.4,
            }));

        const getRandomColor = () => {
            const letters = '0123456789ABCDEF';
            return `#${Array.from({ length: 6 })
                .map(() => letters[Math.floor(Math.random() * 16)])
                .join('')}`;
        };

        const colors = Array.from({ length: utm.data.length }, getRandomColor);

        const createChartData = (labels, dataArray) => ({
            labels,
            datasets: createDataset(dataArray, colors),
        });

        const data = {
            labels: stats.labels,
            datasets: [
                {
                    label: t('conversations'),
                    data: stats.conversations_count,
                    fill: false,
                    borderColor: getColor('--primary-color'),
                    tension: 0.4,
                },
                {
                    label: t('leads'),
                    data: stats.leads_count,
                    fill: false,
                    borderColor: getColor('--blue-500'),
                    tension: 0.4,
                },
                {
                    label: t('appointments'),
                    data: stats.appointments_count,
                    fill: false,
                    borderColor: getColor('--orange-500'),
                    tension: 0.4,
                },

            ],
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            responsive: true,
            interaction: { intersect: false, mode: 'index' },
            plugins: {
                legend: {
                    labels: { color: textColor },
                },
            },
            scales: {
                x: {
                    ticks: { color: textColorSecondary },
                    grid: { color: surfaceBorder },
                },
                y: {
                    ticks: { color: textColorSecondary },
                    grid: { color: surfaceBorder },
                },
            },
        };

        // Generate chart data for each metric
        const data2 = createChartData(utm.labels, utm.data);
        const dataLeads = createChartData(leads.labels, leads.data);
        const dataAppointments = createChartData(appointments.labels, appointments.data);

        // Set chart data and options
        setChartData(data);
        setChartData2(data2);
        setChartDataLead(dataLeads);
        setChartDataAppointments(dataAppointments);
        setChartOptions(options);
    }


    function changeDate(value) {
        let startDate, endDate;

        if (value[1]) {
            startDate = moment(value[0]);
            endDate = moment(value[1]);
        } else {
            startDate = moment(value[0]);
            endDate = moment(value[0]);
        }

        // Define specific ranges
        const todayStart = moment().startOf('day');
        const todayEnd = moment().endOf('day');
        const yesterdayStart = moment().subtract(1, 'day').startOf('day');
        const yesterdayEnd = moment().subtract(1, 'day').endOf('day');
        const last7DaysStart = moment().subtract(7, 'days').startOf('day');
        const last30DaysStart = moment().subtract(30, 'days').startOf('day');
        const thisMonthStart = moment().startOf('month');
        const thisMonthEnd = moment().endOf('month');
        const lastMonthStart = moment().subtract(1, 'month').startOf('month');
        const lastMonthEnd = moment().subtract(1, 'month').endOf('month');
        const thisYearStart = moment().startOf('year');
        const thisYearEnd = moment().endOf('year');
        const lastYearStart = moment().subtract(1, 'year').startOf('year');
        const lastYearEnd = moment().subtract(1, 'year').endOf('year');

        // Check for specific date ranges
        if (startDate.isSame(todayStart, 'day')) {
            if (!value[1] || endDate.isSame(todayStart, 'day')) {
                setDate('today');
            } else {
                setDate('custom');
            }
        } else if (startDate.isSame(yesterdayStart, 'day')) {
            if (!value[1] || endDate.isSame(yesterdayStart, 'day')) {
                setDate('yesterday');
            } else {
                setDate('custom');
            }
        } else if (startDate.isSame(last7DaysStart, 'day') && endDate.isSame(todayEnd, 'day')) {
            setDate('last_7_days');
        } else if (startDate.isSame(last30DaysStart, 'day') && endDate.isSame(todayEnd, 'day')) {
            setDate('last_30_days');
        } else if (startDate.isSame(thisMonthStart, 'day') && endDate.isSame(thisMonthEnd, 'day')) {
            setDate('this_month');
        } else if (startDate.isSame(lastMonthStart, 'day') && endDate.isSame(lastMonthEnd, 'day')) {
            setDate('last_month');
        } else if (startDate.isSame(thisYearStart, 'day') && endDate.isSame(thisYearEnd, 'day')) {
            setDate('this_year');
        } else if (startDate.isSame(lastYearStart, 'day') && endDate.isSame(lastYearEnd, 'day')) {
            setDate('last_year');
        } else {
            setDate('custom');
        }

        // Update datesSend without modifying the original moment objects
        setDatesSend([startDate.clone().add(1, 'day').toDate(), endDate.clone().add(1, 'day').toDate()]);
        // setDatesSend([startDateUTC.clone().toDate(), endDateUTC.clone().toDate()]);

        // Update the primary dates state
        setDates(value);
    }

    function handleChange(value) {
        setDate(value);

        switch (value) {
            case 'today':
                setDates([
                    moment().startOf('day').toDate(),
                    moment().endOf('day').toDate(),
                ]);
                setDatesSend([
                    moment.utc().startOf('day').toDate(),
                    moment.utc().endOf('day').toDate(),
                ]);
                break;
            case 'yesterday':
                setDates([
                    moment().subtract(1, 'days').startOf('day').toDate(),
                    moment().subtract(1, 'days').endOf('day').toDate(),
                ]);
                setDatesSend([
                    moment.utc().subtract(1, 'days').startOf('day').toDate(),
                    moment.utc().subtract(1, 'days').endOf('day').toDate(),
                ]);
                break;
            case 'last_7_days':
                setDates([
                    moment().subtract(7, 'days').startOf('day').toDate(), // Should be 6 days to include today
                    moment().subtract(1, 'days').endOf('day').toDate(),
                ]);
                setDatesSend([
                    moment.utc().subtract(7, 'days').startOf('day').toDate(),
                    moment.utc().subtract(1, 'days').endOf('day').toDate(),
                ]);
                break;
            case 'last_30_days':
                setDates([
                    moment().subtract(29, 'days').startOf('day').toDate(), // Should be 29 days to include today
                    moment().endOf('day').toDate(),
                ]);
                setDatesSend([
                    moment.utc().subtract(29, 'days').startOf('day').toDate(),
                    moment.utc().endOf('day').toDate(),
                ]);
                break;
            case 'this_month':
                setDates([
                    moment().startOf('month').toDate(),
                    moment().endOf('day').toDate(),
                ]);
                setDatesSend([
                    moment.utc().startOf('month').toDate(),
                    moment.utc().endOf('day').toDate(),
                ]);
                break;
            case 'last_month':
                setDates([
                    moment().subtract(1, 'month').startOf('month').toDate(),
                    moment().subtract(1, 'month').endOf('month').toDate(),
                ]);
                setDatesSend([
                    moment.utc().subtract(1, 'month').startOf('month').toDate(),
                    moment.utc().subtract(1, 'month').endOf('month').toDate(),
                ]);
                break;
            case 'this_year':
                setDates([
                    moment().startOf('year').toDate(),
                    moment().endOf('day').toDate(),
                ]);
                setDatesSend([
                    moment.utc().startOf('year').toDate(),
                    moment.utc().endOf('day').toDate(),
                ]);
                break;
            case 'last_year':
                setDates([
                    moment().subtract(1, 'year').startOf('year').toDate(),
                    moment().subtract(1, 'year').endOf('year').toDate(),
                ]);
                setDatesSend([
                    moment.utc().subtract(1, 'year').startOf('year').toDate(),
                    moment.utc().subtract(1, 'year').endOf('year').toDate(),
                ]);
                break;

            default:
                setDates([]);
                setDatesSend([]);
        }
    }


    const urlBody = (rowData) => {
        return <>
            <NavLink to={rowData.url} target='_blank' className="font-medium no-underline ml-2 hover:text-primary cursor-pointer">{rowData.url}</NavLink>
            <span className='ml-2 font-medium'>({rowData.count})</span>
        </>
    }

    const title = () => {
        return <>
            <span className='text-xl'>
                {t('customers_mostly_start_chats_from_these_pages')}
            </span>
        </>
    }

    function calcPercentage(n1, n2) {
        if (typeof n1 === "string" && n1.includes("%")) {
            n1 = parseFloat(n1.replace("%", ""));
        }

        if (typeof n2 === "string" && n2.includes("%")) {
            n2 = parseFloat(n2.replace("%", ""));
        }

        const percentage = n1 - n2;

        return percentage.toFixed(2);
    }



    const onPageChange = (event) => {
        setFirst(event.first);
        setPerPage(event.rows);
        fetchStatistics(event.rows, event.page + 1)
    };

    useEffect(() => {
        document.title = 'DialogEase - ' + t('statistic')
    }, [])

    return (
        <>
            <div className="mx-auto flex max-w-8xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('statistic')}</h4>

                <div className='space-x-2'>
                    <Dropdown value={date} onChange={(e) => handleChange(e.value)} options={datesOptions} optionLabel="name" className='min-w-44'
                        placeholder={t('this_period')} />

                    <Calendar value={dates} onChange={(e) => changeDate(e.value)} selectionMode="range" readOnlyInput dateFormat="yy/mm/dd" />
                </div>

            </div>


            <div className="grid">
                <div className="col-12 md:col-4">
                    <div className='p-card p-3 h-full'>
                        <h4 className="my-1 text-2xl font-extrabold">{t('number_of_conversations')}</h4>
                        <h2 className='text-2xl font-semibold mt-2'>{conversations['new']}</h2>

                        <div className='flex  items-center justify-between mt-2'>
                            <span className='font-semibold'>{conversations['old']}</span>

                            {
                                conversations['percentageChange'] > 0 ?
                                    <div className='text-green-500 flex gap-1 items-center font-semibold'>
                                        <span className="pi pi-arrow-up"></span>
                                        <span>{conversations['percentage']}</span>
                                    </div> :
                                    conversations['percentageChange'] == 0 ?
                                        <div className='text-orange-500 flex gap-1 items-center font-semibold'>
                                            <span>{conversations['percentage']}</span>
                                        </div>
                                        :
                                        <div className='text-red-500 flex gap-1 items-center font-semibold'>
                                            <span className="pi pi-arrow-down"></span>
                                            <span>{conversations['percentage']}</span>
                                        </div>
                            }
                        </div>

                    </div>
                </div>

                <div className="col-12 md:col-4">
                    <div className='p-card p-3 h-full'>
                        <h4 className="my-1 text-2xl font-extrabold">{t('number_of_leads')}</h4>
                        <h2 className='text-2xl font-semibold mt-2'>{leads['new']}</h2>

                        <div className='flex  items-center justify-between mt-2'>
                            <span className='font-semibold'>{leads['old']}</span>
                            {
                                leads['percentageChange'] > 0 ?
                                    <div className='text-green-500 flex gap-1 items-center font-semibold'>
                                        <span className="pi pi-arrow-up"></span>
                                        <span>{leads['percentage']}</span>
                                    </div> :
                                    leads['percentageChange'] == 0 ?
                                        <div className='text-orange-500 flex gap-1 items-center font-semibold'>
                                            <span>{leads['percentage']}</span>
                                        </div>
                                        :
                                        <div className='text-red-500 flex gap-1 items-center font-semibold'>
                                            <span className="pi pi-arrow-down"></span>
                                            <span>{leads['percentage']}</span>
                                        </div>
                            }
                        </div>

                    </div>
                </div>

                <div className="col-12 md:col-4">
                    <div className='p-card p-3 h-full'>
                        <h4 className="my-1 text-2xl font-extrabold">{t('number_of_rdv')}</h4>
                        <h2 className='text-2xl font-semibold mt-2'>{appointments['new']}</h2>

                        <div className='flex  items-center justify-between mt-2'>
                            <span className='font-semibold'>{appointments['old']}</span>
                            {
                                appointments['percentageChange'] > 0 ?
                                    <div className='text-green-500 flex gap-1 items-center font-semibold'>
                                        <span className="pi pi-arrow-up"></span>
                                        <span>{appointments['percentage']}</span>
                                    </div> :
                                    appointments['percentageChange'] == 0 ?
                                        <div className='text-orange-500 flex gap-1 items-center font-semibold'>
                                            <span>{appointments['percentage']}</span>
                                        </div>
                                        :
                                        <div className='text-red-500 flex gap-1 items-center font-semibold'>
                                            <span className="pi pi-arrow-down"></span>
                                            <span>{appointments['percentage']}</span>
                                        </div>
                            }
                        </div>
                    </div>
                </div>


                <div className="col-12 md:col-6">
                    <div className='p-card p-3 h-full'>
                        <h4 className="my-1 text-2xl font-extrabold">{t('leads_conversion_rate')}</h4>
                        <h2 className='text-2xl font-semibold mt-2'>{leadConversionRate['percentage']}</h2>

                        <div className='flex  items-center justify-between mt-2'>
                            <span className='font-semibold '>{leadConversionRate['percentageOld']}</span>
                            {
                                calcPercentage(leadConversionRate['percentage'], leadConversionRate['percentageOld']) > 0 ?
                                    <div className='text-green-500 flex gap-1 items-center font-semibold'>
                                        <span className="pi pi-arrow-up"></span>
                                        <span>+{calcPercentage(leadConversionRate['percentage'], leadConversionRate['percentageOld'])}%</span>
                                    </div> :
                                    calcPercentage(leadConversionRate['percentage'], leadConversionRate['percentageOld']) == 0 ?
                                        <div className='text-orange-500 flex gap-1 items-center font-semibold'>
                                            <span>{calcPercentage(leadConversionRate['percentage'], leadConversionRate['percentageOld'])}%</span>
                                        </div>
                                        :
                                        <div className='text-red-500 flex gap-1 items-center font-semibold'>
                                            <span className="pi pi-arrow-down"></span>
                                            <span>{calcPercentage(leadConversionRate['percentage'], leadConversionRate['percentageOld'])}%</span>
                                        </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-12 md:col-6">
                    <div className='p-card p-3 h-full'>
                        <h4 className="my-1 text-2xl font-extrabold">{t('appointments_conversion_rate')}</h4>
                        <h2 className='text-2xl font-semibold mt-2 '>{appointmentConversionRate['percentage']}</h2>

                        <div className='flex  items-center justify-between mt-2'>
                            <span className='font-semibold '>{appointmentConversionRate['percentageOld']}</span>
                            {
                                calcPercentage(appointmentConversionRate['percentage'], appointmentConversionRate['percentageOld']) > 0 ?
                                    <div className='text-green-500 flex gap-1 items-center font-semibold'>
                                        <span className="pi pi-arrow-up"></span>
                                        <span>+{calcPercentage(appointmentConversionRate['percentage'], appointmentConversionRate['percentageOld'])}%</span>
                                    </div> :
                                    calcPercentage(appointmentConversionRate['percentage'], appointmentConversionRate['percentageOld']) == 0 ?
                                        <div className='text-orange-500 flex gap-1 items-center font-semibold'>
                                            <span>{calcPercentage(appointmentConversionRate['percentage'], appointmentConversionRate['percentageOld'])}%</span>
                                        </div>
                                        :
                                        <div className='text-red-500 flex gap-1 items-center font-semibold'>
                                            <span className="pi pi-arrow-down"></span>
                                            <span>{calcPercentage(appointmentConversionRate['percentage'], appointmentConversionRate['percentageOld'])}%</span>
                                        </div>
                            }
                        </div>
                    </div>
                </div>

            </div>

            <div className="grid mt-1">

                <div className="col-12 md:col-6">
                    <div className="p-card p-3">
                        <div className='text-center font-bold text-2xl mb-2'>{t('leads_appointements_conversations')}</div>
                        <Chart type="line" data={chartData} options={chartOptions} />
                    </div>
                </div>

                <div className="col-12 md:col-6">
                    <div className="p-card p-3">
                        <div className='text-center font-bold text-2xl mb-2'>{t('conversation_by_sources')}</div>
                        <Chart type="line" data={chartData2} options={chartOptions} />
                    </div>
                </div>

                <div className="col-12 md:col-6">
                    <div className="p-card p-3">
                        <div className='text-center font-bold text-2xl mb-2'>{t('lead_by_sources')}</div>
                        <Chart type="line" data={chartDataLead} options={chartOptions} />
                    </div>
                </div>

                <div className="col-12 md:col-6">
                    <div className="p-card p-3">
                        <div className='text-center font-bold text-2xl mb-2'>{t('appointment_by_sources')}</div>
                        <Chart type="line" data={chartDataAppointments} options={chartOptions} />
                    </div>
                </div>

            </div>

            <Card title={title} subTitle={t('from_all_your_conversations')} className='mt-4'>
                <DataTable value={urls} tableStyle={{ minWidth: '50rem' }} emptyMessage={t('no_links_found')}  >
                    <Column field="url" header={t('urls')} body={urlBody}></Column>
                </DataTable>
                {
                    total > 0 &&
                    <Paginator className='dir-ltr' first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 50, 100]} />
                }
            </Card>

        </>
    )
}
