import React, { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { resetPassword } from './authActions';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';





export default function ResetPassword() {
    const { t } = useTranslation()
    const { token, email } = useParams();
    const navigate = useNavigate();
    const toast = useRef(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const handleSubmit = (e) => {
        e.preventDefault();
        var params = {
            token,
            email,
            password,
            password_confirmation: confirmPassword,
        };
        resetPassword(params).then((res) => {
            toast.current.show({ severity: 'success', summary: "Success", detail: res.data.message, life: 3000 });
            navigate('/login', { state: { message: res.data.message } });
        }).catch((err) => {
            setError(err.response.data.message);
        })
    };

    useEffect(() => {
        document.title = 'DialogEase - ' + t('reset_password')
    }, [])

    return (
        <div className="flex align-items-center justify-content-center min-h-screen">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-5">
                <div className="flex flex-col items-center mb-5">
                    <img src="/images/logo.png" alt="hyper" height={10} className="mb-3" />
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="password" className="block text-900 font-medium mb-2">{t('password')}</label>
                        <InputText id="password" type="password" placeholder={t('password')} className="w-full"
                            value={password}
                            invalid={error}
                            onChange={(e) => setPassword(e.target.value)} />
                        {error && (
                            <small className="text-red-500">{error}</small>)}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="confirm_password" className="block text-900 font-medium mb-2">{t('confirm_password')}</label>
                        <InputText id="confirm_password" type="password" placeholder={t('confirm_password')} className="w-full"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>

                    <Button label={t('submit')} className="w-full" />
                </form>
            </div>
            <Toast ref={toast} />
        </div>
    )
}
