import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./Auth/store"; 
import MyApp from "./_app";
import './i18n'; 

const root = ReactDOM.createRoot(document.getElementById("app"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <MyApp />
        </Provider>
    </React.StrictMode>
);
