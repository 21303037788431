import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const getUser = async () => {
    await Csrf.getCookie();
    return await Api.get("/profile");
}

export const updateUser = async (params) => {
    await Csrf.getCookie();
    return await Api.put("/profile/edit", params);
}

export const updatePassword = async (params) => {
    await Csrf.getCookie();
    return await Api.put("/password/edit", params);
}