import React, { useState, useRef, useEffect } from 'react'
import ChatTemplate from '../chat/ChatTemplate'
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { playground } from '../../../services/Chatbot';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import CustomBot from '../CustomBot'
import { TabView, TabPanel } from 'primereact/tabview';
import { Prompte } from './Settings/ChatInterface';
import { customChatToggle } from '../../../Auth/authActions';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';


export default function IndexBot() {
    const { t } = useTranslation()
    const toast = useRef(null);
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [isVisible, setIsVisible] = useState(false);
    const [instructions, setInstructions] = useState("");
    const [old, setOld] = useState("");
    const [show, setShow] = useState(false);
    const [customChat, setCustomChat] = useState(false);
    const [preload, setPreload] = useState(false);





    function save() {
        playground(slug, { instructions }).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message });
            setOld(instructions)
            setIsVisible(false);
        })
    }

    function onHide() {
        setCustomChat(false)
        setPreload(true)

        setTimeout(() => {
            setPreload(false)
        }, 1)
    }

    const toggleDiv = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        document.title = 'DialogEase - ' + t('playground')
    }, [])

    return (
        <>
            <Toast ref={toast} />
            <div className="mx-auto flex max-w-7xl flex-row justify-between px-1 py-5">
                <h4 className="my-1 text-3xl font-bold">{t('playground')}</h4>
            </div>


            <div className=' relative p-4  bg-zinc-100 rounded-md'>

                <div className='absolute left-2 z-2 top-2'>
                    <div className="flex flex-col gap-2">
                        <Button icon="pi pi-bars" severity="secondary" onClick={toggleDiv} />
                    </div>
                </div>

                <div className='max-md:pt-9'>
                    <ChatTemplate slug={slug} preload={preload} setInstructions={setInstructions} setOld={setOld} setShow={setShow} />
                </div>

                <div className='absolute right-2 top-2 z-2'>
                    <div className="flex flex-col gap-2">
                        <Button label={t('custom_chat')} onClick={() => setCustomChat(true)} className="" />
                    </div>
                </div>
            </div>



            <Sidebar visible={isVisible} onHide={() => setIsVisible(false)}>
                <div>
                    <div className="w-full">
                        <label htmlFor="Instructions" className="block text-500 font-medium ">{t('instructions')}</label>
                        <InputTextarea id="Instructions" type="text" placeholder={t('instructions')} rows={10} className="w-full"
                            value={instructions} onChange={(e) => setInstructions(e.target.value)}
                        />
                    </div>
                </div>
                <div className='flex gap-1 mt-4'>
                    <Button label={t('save')} className='w-full' onClick={save} disabled={instructions === old} />
                </div>
            </Sidebar>


            <Sidebar header={t('custom_chat')} visible={customChat} className='w-[100%] 2xl:w-[70%] xl:w-[80%] lg:w-[90%] ' position="right" onHide={onHide}>
                <TabView>
                    <TabPanel header={t('prompt')}>
                        <Prompte />
                    </TabPanel>
                    <TabPanel header={t('custom_bot')}>
                        <CustomBot />
                    </TabPanel>
                </TabView>
            </Sidebar>
        </>
    )
}
