import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest, loginSuccess, logout, sendVerifyEmail } from '../../Auth/authActions';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { changeLang } from '../../services/Settings';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { loginUser } from '../../services/Admin';
import Cookies from 'js-cookie';


export default function NavBar() {
    const { t } = useTranslation()
    const user = useSelector(state => state.auth.user)
    const isExpired = useSelector(state => state.auth.isExpired)
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const msgs = useRef(null);

    const navigate = useNavigate();
    const menuLeft = useRef(null);


    function capitalizeName(name) {
        return name.replace(/\b\w/g, char => char.toUpperCase());
    }

    const items = [
        {
            label: capitalizeName(user.name),
            items: [
                {
                    label: t('dashboard'),
                    icon: 'pi pi-home',
                    command: () => {
                        navigate('/dashboard');
                    }
                },
                {
                    label: t('account_settings'),
                    icon: 'pi pi-cog',
                    command: () => {
                        navigate('/dashboard/settings');
                    }
                },
                {
                    label: t('sign_out'),
                    icon: 'pi pi-sign-out',
                    command: () => {
                        log_out();
                    }
                },

            ]
        },
    ];


    if (user.admin == 1 || Cookies.get('KQzY4sGwrVgt2GjDyhp6s')) {
        items[0].items.unshift(
            {
                label: t('Admin'),
                icon: 'pi pi-warehouse',
                command: () => {
                    if (Cookies.get('KQzY4sGwrVgt2GjDyhp6s')) {
                        const email_admin = Cookies.get('KQzY4sGwrVgt2GjDyhp6s');
                        // dispatch(logout());

                        dispatch(loginRequest());

                        loginUser(email_admin).then((res) => {
                            let data = res.data;
                            dispatch(loginSuccess(data));
                            Cookies.remove('KQzY4sGwrVgt2GjDyhp6s');
                            navigate('/admin');
                        })
                    } else {
                        navigate('/admin');
                    }
                }

            })
    } else {
        items[0].items.splice(2, 0,
            {
                label: t('tickets'),
                icon: 'pi pi-ticket',
                command: () => {
                    navigate('/dashboard/tickets');
                }
            }
        );
    }


    function log_out() {
        dispatch(logout(navigate));
    }


    useEffect(() => {
        if (msgs.current && isExpired.expired) {
            msgs.current.clear();
            msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: 'Warn', detail: isExpired.message, closable: true });
        }
    }, [isExpired])


    return (
        <div>
            <nav className="bg-white w-full border-b border-gray-200 ">
                <div className="flex flex-wrap justify-center items-center gap-1 min-[640px]:justify-between p-2 xl:mx-6">
                    <img
                        src="/images/logo.png"
                        className="h-4rem cursor-pointer"
                        alt="Logo"
                        onClick={() => navigate("/")}
                    />

                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        <SelectLang />

                        <Avatar
                            icon="pi pi-user"
                            style={{
                                height: "45px ",
                                width: "45px",
                                padding: "13px",
                            }}
                            shape="circle"
                            onClick={(event) => menuLeft.current.toggle(event)}
                            aria-controls="popup_menu_left"
                        />
                        <Menu
                            model={items}
                            popup
                            ref={menuLeft}
                            id="popup_menu_left"
                        />
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none"
                            aria-controls="navbar-sticky"
                            aria-expanded={isOpen}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 17 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M1 1h15M1 7h15M1 13h15"
                                />
                            </svg>
                        </button>
                    </div>
                    <div
                        id="navbar-sticky"
                        className={` items-center justify-between hidden w-full md:flex md:w-auto md:order-1 ${isOpen ? "block" : "hidden"
                            }`}
                    >
                        <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <NavLink
                                    to="/dashboard/chatbots"
                                    className={({ isActive }) =>
                                        `${isActive
                                            ? "text-primary"
                                            : "text-gray-900"
                                        } block py-2 px-3  rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("chatbots")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/dashboard/usage"
                                    className={({ isActive }) =>
                                        `${isActive ? "text-primary" : ""
                                        } block py-2 px-3 text-gray-900 rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("usage")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/dashboard/settings"
                                    className={({ isActive }) =>
                                        `${isActive ? "text-primary" : ""
                                        } block py-2 px-3 text-gray-900 rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("settings")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/dashboard/appointment"
                                    className={({ isActive }) =>
                                        `${isActive ? "text-primary" : ""
                                        } block py-2 px-3 text-gray-900 rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("appointment")}
                                </NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/dashboard/leads"
                                    className={({ isActive }) =>
                                        `${isActive ? "text-primary" : ""
                                        } block py-2 px-3 text-gray-900 rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("leads")}
                                </NavLink>
                            </li>

                            {/* <li>
                                <NavLink
                                    to="/dashboard/docs"
                                    className={({ isActive }) =>
                                        `${isActive ? "text-primary" : ""
                                        } block py-2 px-3 text-gray-900 rounded hover:!text-[var(--primary-color)] md:p-0`
                                    }
                                >
                                    {t("docs")}
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            <div className='mx-auto max-w-5xl'>
                <Messages ref={msgs} />
            </div>

            {
                (isExpired.verified == false) &&
                <VerifyEmail />
            }

        </div>
    );
}

export function SelectLang() {
    const user = useSelector(state => state.auth.user)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const { i18n } = useTranslation();
    const countries = [
        { name: 'English', code: 'en' },
        { name: 'Français', code: 'fr' },
        { name: 'العربية', code: 'ar' },
    ];

    const changeLanguage = (lng) => {
        changeLang(lng.code)
        setSelectedCountry(lng)
        i18n.changeLanguage(lng.code);

        if (lng.code == 'ar') {
            document.body.dir = 'rtl';
        } else {
            document.body.dir = 'ltr';
        }

    };

    useEffect(() => {
        if (user) {
            setSelectedCountry(countries.find((c) => c.code === user.lang));
            i18n.changeLanguage(user.lang);

            if (user.lang == 'ar') {
                document.body.dir = 'rtl';
            } else {
                document.body.dir = 'ltr';
            }
        }
    }, [user]);

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.code} src={`/images/${option.code.toLowerCase()}.png`} className={`ltr:mr-2 rtl:ml-2`} style={{ width: '30px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.code} src={`/images/${option.code.toLowerCase()}.png`} className={`ltr:mr-2 rtl:ml-2`} style={{ width: '30px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selectedCountry} onChange={(e) => changeLanguage(e.value)} options={countries} optionLabel="name" placeholder="Select a Country"
                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="ltr:mr-2 rtl:ml-2 w-12rem" />
        </div>
    )
}


export function VerifyEmail() {
    const toast = useRef(null);
    const { t } = useTranslation();
    const [send, setSend] = useState(false);
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (time > 0) {
            const interval = setInterval(() => {
                setTime(prev => {
                    if (prev === 1) {
                        clearInterval(interval);
                        setSend(false);
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [time]);


    const handleSend = () => {
        sendVerifyEmail({ next: window.location.pathname }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
            setTime(60);
            setSend(true);
        });
    };

    return (
        <div className='backdrop-blur-[2px] w-full h-screen fixed top-0 z-20 '>
            <div className='flex justify-center items-center h-full'>

                <div className="surface-0 text-700 text-center p-card p-10">
                    <div className="text-700 text-2xl mb-5">{t('description_verify_email')}</div>
                    {
                        send ?
                            <Button label={t('resend_after') + ' ' + time + ' s'} disabled /> :
                            <Button label={t('verify_email')} onClick={handleSend} />
                    }

                </div>
                <Toast ref={toast} />
            </div>
        </div>
    );
}