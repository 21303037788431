import Api from '../services/Api';
import Csrf from '../services/Csrf';

export const loginRequest = () => ({
    type: 'LOGIN_REQUEST',
});

export const loginSuccess = (userData) => ({
    type: 'LOGIN_SUCCESS',
    payload: userData,
});


export const checkExpired = (data) => ({
    type: 'CHECK_EXPIRED',
    payload: data,
});
export const loginFailure = (error) => ({
    type: 'LOGIN_FAILURE',
    payload: error,
});

export const logoutRequest = () => ({
    type: 'LOGOUT_REQUEST',
});

export const customChatToggle = (boll) => ({
    type: 'CUSTOM_CHAT',
    payload: boll,
});

export const chatToggle = (boll) => async (dispatch) => {
    dispatch(customChatToggle(boll));
}


export const login = (credentials, navigate, toast, next = null) => async (dispatch) => {
    dispatch(loginRequest());
    try {
        await Csrf.getCookie();
        const response = await Api.post("/login", credentials);
        dispatch(loginSuccess(response.data));

        if (next) {
            navigate(next);
        } else {
            navigate('/dashboard');
        }

    } catch (error) {
        dispatch(loginFailure(error.response.data.errors));

        if (error.status === 422) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: error.response.data.message, life: 3000 });
        }
    }
};

export const fetchUser = () => async (dispatch) => {
    try {
        await Csrf.getCookie();
        const response = await Api.get("/me");
        const resCheck = await Api.get("/check-expired");
        localStorage.setItem('language', response.data.lang);
        dispatch(loginSuccess(response.data));
        dispatch(checkExpired(resCheck.data));
    } catch (error) {
        // console.error(error)
    }
};

export const logout = (navigate = null) => async (dispatch) => {
    try {
        await Csrf.getCookie();
        await Api.post("/logout");
        dispatch(logoutRequest())


        // Clear cookies and session storage.
        document.cookie = 'chatai_session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'XSRF-TOKEN=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        sessionStorage.clear();  // Clear all session storage
        localStorage.clear();    // Clear all local storage (if used)

        document.body.dir = 'ltr';
        if (navigate) {
            navigate('/login')
        }
    } catch (error) {
        // dispatch(loginFailure(error.message));
        // console.error(error)
    }
};

export const forgotPassword = async (email) => {
    await Csrf.getCookie();
    return await Api.post("/password/email", email);
}


export const resetPassword = async (params) => {
    await Csrf.getCookie();
    return await Api.post("/password/reset", params);
}


export const signup = async (params) => {
    await Csrf.getCookie();
    return await Api.post("/signup", params);
}

export const sendVerifyEmail = async (params) => {
    await Csrf.getCookie();
    return await Api.post("/verify-email", params);
}