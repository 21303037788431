import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { deleteUser, generateToken, getUsers, loginUser } from '../../services/Admin';
import { Tooltip } from 'primereact/tooltip';
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import Signup from '../../Auth/signup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest, loginSuccess, logout } from '../../Auth/authActions';
import Cookies from 'js-cookie';


export default function Users() {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const message = location.state?.message;

    useEffect(() => {
        if (message) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        }
    }, [message]);


    return (
        <>
            <div className="flex  justify-between px-1 py-5">
                <h4 className="text-3xl font-bold my-1">{t('users')}</h4>
                {/* <Button label={t('add_new_user')} onClick={() => setVisible(true)} /> */}
            </div>
            <RowExpansionDemo />

            <Dialog header={t('add_new_user')} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                <Signup />
            </Dialog>
            <Toast ref={toast} />
        </>
    );
}


function RowExpansionDemo() {
    const [users, setUsers] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const toast = useRef(null);
    const { t } = useTranslation();
    const [perPage, setPerPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [first, setFirst] = useState(0);
    const abortController = new AbortController();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user)


    function fetchUsers(page = 1) {

        getUsers(page, abortController).then(res => {
            setUsers(res.data.users)
            setPerPage(res.data.per_page);
            setTotal(res.data.total);
        })
            .catch(error => toast.current.show({ severity: 'error', summary: 'Error Loading Data', detail: error.message, life: 5000 }));

    }

    useEffect(() => {
        return () => abortController.abort();
    }, []);

    useEffect(() => {
        fetchUsers()
    }, []);

    const toggleRowExpansion = (id) => {
        setExpandedRows(prevExpandedRows => {
            const isCurrentlyExpanded = prevExpandedRows[id];
            const updatedExpandedRows = { ...prevExpandedRows };

            if (isCurrentlyExpanded) {
                // If the row is currently expanded, remove it to collapse
                delete updatedExpandedRows[id];
            } else {
                // If the row is not expanded, add it to expand
                updatedExpandedRows[id] = true;
            }

            return updatedExpandedRows;
        });
    };



    function formatDate(data) {
        const date = new Date(data.created_at);
        let language = localStorage.getItem('language') || 'en';

        const options = {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        };

        const optionsTooltip = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };


        const formattedDate = new Intl.DateTimeFormat(language, options).format(date);
        const formattedDateTooltip = new Intl.DateTimeFormat(language, optionsTooltip).format(date);

        const dateClass = `date-${date.getTime()}`;

        return (
            <>
                <Tooltip target={`.${dateClass}`} />
                <p data-pr-tooltip={formattedDateTooltip} data-pr-position="bottom" className={dateClass}>{formattedDate}</p>
            </>
        );
    }



    function tokens_by_model(data) {
        // Check if tokens_by_model is undefined or an empty object
        if (!data.tokens_by_model || Object.keys(data.tokens_by_model).length === 0) {
            return <div ><b>{data.model}:</b> {data.tokens}</div>;
        }

        return (
            <>
                {Object.entries(data.tokens_by_model).map(([model, tokens], index) => (
                    <div key={index}><b>{model}:</b> {tokens}</div>
                ))}
            </>
        );
    }

    const onPageChange = (event) => {
        setFirst(event.first);
        fetchUsers(event.page + 1)
    };

    function handleDelete(id) {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete user',
            icon: 'pi pi-info-circle',
            // defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => {
                deleteUser(id).then((res) => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: res.data.message, life: 3000 });
                    fetchUsers();
                });
            },
            reject: () => { }
        });
    }


    function HandleLogin(email) {

        generateToken().then(res => {
            const email_admin = res.data;

            dispatch(loginRequest());

            loginUser(email).then((res) => {
                let data = res.data;
                Cookies.set('KQzY4sGwrVgt2GjDyhp6s', email_admin);

                dispatch(loginSuccess(data));
                navigate('/dashboard');

            })
        })

    }

    const actions = data => (
        <div className='flex gap-1'>
            <Button icon={expandedRows[data.id] ? "pi pi-eye-slash" : "pi pi-eye"} className="p-button-rounded" aria-label="View" onClick={() => toggleRowExpansion(data.id)} />
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" aria-label="Edit" onClick={() => HandleLogin(data.email)} />
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" aria-label="Delete" onClick={() => handleDelete(data.id)} />
        </div>
    );

    const capitalizeName = data => data.name.replace(/\b\w/g, char => char.toUpperCase());

    const rowExpansionTemplate = data => (
        <div className="p-3">
            <h5>{t('assistants_for')} <b> {capitalizeName(data)} </b></h5>
            <DataTable value={data.ais}>
                {/* <Column field="slug" header={t('chat_id')} sortable /> */}
                <Column field="name" header={t('name')} sortable />
                <Column field="tokens" header={t('tokens_consumed')} sortable />
                <Column field="tokens_by_model" header={t('tokens_consumed_by_model')} sortable body={tokens_by_model} />
                <Column field="model" header={t('current_model')} sortable />
                <Column field="created_at" header={t('created_at')} body={formatDate} sortable />
            </DataTable>
        </div>
    );

    return (
        <div className="card min-h-screen">
            <Toast ref={toast} />
            <DataTable value={users} expandedRows={expandedRows} onRowToggle={e => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="id" responsiveLayout="scroll">
                <Column expander style={{ width: '3em' }} />
                <Column field="name" header={t('name')} sortable body={capitalizeName} />
                <Column field="email2" header={t('email')} sortable />
                <Column field="created_at" header={t('created_at')} sortable body={formatDate} />
                <Column field="active" header={t('active')} sortable />
                <Column field="tokens" header={t('tokens_consumed')} sortable />
                <Column body={actions} header={t('actions')} />
            </DataTable>

            <div className='mt-4'>
                <Paginator first={first} rows={perPage} totalRecords={total} onPageChange={onPageChange} />
            </div>

            <ConfirmDialog draggable={false} />
            <Toast ref={toast} />
        </div>
    );
}
